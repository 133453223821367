import {
  ClockCircleOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  ImportOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import { parsePhoneNumber } from 'awesome-phonenumber';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import { useManyRemote } from '../../../shared/use-many-remote';
import useProfile from '../../../shared/use-profile';
import useSafeState from '../../../shared/use-safe-state';
import SelectTenant from '../../tenants/SelectTenant';
import EnterImeiModal from '../EnterImeiModal';
import { AuditStatuses, searchJoon3DevicesQuery } from '../constants';

const { Title, Text } = Typography;

function SelectConfStatus({ value, onChange, ...other }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select a configuration status"
      allowClear
      {...other}
    >
      {Object.values(AuditStatuses).map((field) => (
        <Select.Option key={field.key} value={field.key}>
          {field.label}
        </Select.Option>
      ))}
    </Select>
  );
}

function ListJoonDevices() {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const [showImeiModal, setShowImeiModal] = useState(false);

  const handleCreate = useCallback(
    (joonDeviceId) => {
      history.push(`joon-device/edit/${joonDeviceId}`, { mode: 'create' });
    },
    [history],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({});
  const {
    error,
    loading,
    data: joonDevices,
    refetch,
    search: joonDeviceSearch,
    hasNextPage,
    next,
  } = useManyRemote(
    searchJoon3DevicesQuery,
    (data) => data.searchJoon3Devices,
    settings.querySize,
    filters.current,
    sortBy.current,
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        joonDeviceSearch(changed.search);
      }
    },
    [joonDeviceSearch],
  );

  const onFiltersChange = useCallback((changed) => {
    filters.current = {
      ...filters.current,
      ...changed,
    };
    sortBy.current = [
      { key: '_score', order: 'DESC' },
      { key: sortedBy, order: sortOrder },
    ];
    refetch();
  }, []);

  const profile = useProfile();
  const showCreate = useMemo(() => {
    if (profile && profile.role && profile.role.permissions) {
      return profile.role.permissions.includes('create-joon3:all');
    }
    return false;
  }, [profile]);
  const showUpdate = useMemo(() => {
    if (profile && profile.role && profile.role.permissions) {
      return profile.role.permissions.includes('update-joon3:all');
    }
    return false;
  }, [profile]);

  const [showFilters, setShowFilters] = useState();
  const showTenantsPage = useMemo(() => {
    return profile?.role?.permissions?.includes('list-tenants:all');
  }, [profile]);

  const [searchWords, setSearchWords, _searchWords] = useSafeState({});
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchWords({ ..._searchWords.current, [dataIndex]: selectedKeys[0] });
    filters.current = {
      ...filters.current,
      [dataIndex]: selectedKeys[0],
    };
    sortBy.current = [
      { key: '_score', order: 'DESC' },
      { key: sortedBy, order: sortOrder },
    ];
    refetch();
  };
  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchWords({ ...searchWords.current, [dataIndex]: undefined });
  };
  const getColumnSearchProps = (dataIndex, hint) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        {hint && (
          <div style={{ color: settings.colors.textGray, marginBottom: 12 }}>
            {hint}
          </div>
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <div style={{ width: 60 }} />
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? settings.colors.red : undefined,
          fontSize: filtered ? 16 : undefined,
        }}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchWords[dataIndex] !== undefined ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: settings.colors.yellow,
            padding: 0,
            borderRadius: 2,
          }}
          searchWords={[searchWords[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return (
    <CSPage
      title="Theora Connect&trade; Devices"
      containerStyle={{ maxWidth: 'unset' }}
    >
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <ClockCircleOutlined style={{ marginRight: 16 }} />
            Theora Connect&trade; Devices
          </Title>
        }
        topActions={
          showCreate
            ? [
                <Button
                  key="add"
                  type="link"
                  onClick={() => setShowImeiModal(true)}
                  icon={<PlusCircleOutlined />}
                  size="large"
                >
                  Add a new Theora Connect Device
                </Button>,
                <Link key="import" to="/joon-devices/import">
                  <Button type="link" icon={<ImportOutlined />} size="large">
                    Import a CSV or XLSX
                  </Button>
                </Link>,
              ]
            : undefined
        }
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Form
            layout="vertical"
            form={form1}
            style={{ maxWidth: 500, minWidth: 300, marginRight: 8 }}
            onValuesChange={onSearchChange}
          >
            <Tooltip
              title="Search IMEI, Serial No., Description, Tags, User Names, Tenant Name,
          Owner Name, Wearer Name, and Notes"
            >
              <Form.Item name="search" style={{ marginBottom: 16 }}>
                <Input placeholder="Search" suffix={<SearchOutlined />} />
              </Form.Item>
            </Tooltip>
          </Form>
          <Button
            icon={<FilterOutlined />}
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? 'Less Filters' : 'More Filters'}
          </Button>
        </div>
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      {showFilters && (
        <div style={{ display: 'flex' }}>
          <Form
            layout="vertical"
            form={form2}
            style={{ maxWidth: 500, minWidth: 300, marginRight: 8 }}
            onValuesChange={onFiltersChange}
          >
            {showTenantsPage && (
              <Form.Item label="Tenant" name="tenantId" rules={[]}>
                <SelectTenant
                  disabled={!joonDevices.length && loading}
                  allowClear
                />
              </Form.Item>
            )}
            <Form.Item
              label="Configuration Status"
              name="auditStatus"
              rules={[]}
            >
              <SelectConfStatus disabled={!joonDevices.length && loading} />
            </Form.Item>
          </Form>
        </div>
      )}
      <Table
        dataSource={joonDevices}
        loading={!joonDevices.length && loading}
        pagination={{ ...pagination, total: joonDevices.length }}
        onChange={handleTableChange}
        style={{ width: '100%', marginTop: 16 }}
        rowKey="_id"
        scroll={{
          x: 1600,
        }}
      >
        <Column
          title="IMEI"
          dataIndex="_id"
          key="_id"
          {...getColumnSearchProps('_id')}
        />
        <Column
          title="Serial No."
          dataIndex="serialNo"
          key="serialNo"
          {...getColumnSearchProps('serialNo')}
        />
        <Column
          title="ICCID"
          dataIndex="iccid"
          key="iccid"
          {...getColumnSearchProps('iccid')}
        />
        <Column
          title="Phone Number"
          key="msisdn"
          dataIndex="msisdn"
          {...getColumnSearchProps(
            'msisdn',
            'Remove plus (+), hyphens (-) and spaces when searching.',
          )}
          render={(text, record) => {
            if (record.msisdn) {
              const parsed = parsePhoneNumber(record.msisdn, {
                regionCode: 'US',
              });
              return parsed && parsed.number && parsed.number.international;
            }
            return null;
          }}
        />
        <Column
          title="Call Center ID"
          dataIndex="callCenterId"
          key="callCenterId"
          {...getColumnSearchProps('callCenterId')}
        />
        <Column
          title="Configuration"
          dataIndex="auditStatus"
          render={(text, record) => {
            const status =
              record.auditStatus && AuditStatuses[record.auditStatus];
            if (status) {
              return <Tag color={status.color}>{status.label}</Tag>;
            }
            return null;
          }}
        />
        <Column
          title="Tags"
          dataIndex="tags"
          key="tags"
          {...getColumnSearchProps(
            'tags',
            'Replace any punctuation with spaces.',
          )}
          render={(text, record) => {
            return (
              record.tags &&
              record.tags.map((tag) => (
                <Tag
                  key={tag}
                  color={settings.colors.secondary}
                  style={{ marginBottom: 4 }}
                >
                  {tag}
                </Tag>
              ))
            );
          }}
        />
        {showCreate && (
          <Column
            title="Tenant"
            dataIndex="tenantId"
            render={(text, record) => {
              return record.tenantName;
            }}
          />
        )}
        <Column
          title="Users"
          dataIndex="userNames"
          key="userNames"
          render={(text, record) => {
            return record.userNames?.join(', ');
          }}
        />
        {/* <Column
          title="SIM Status"
          dataIndex="simStatus"
          render={(text, record) => {
            return (
              record.simStatus && titleCase(record.simStatus.toLowerCase())
            );
          }}
        />
        <Column
          title="Activation Date"
          dataIndex="activatedAt"
          render={(text, record) => {
            return (
              record.activatedAt &&
              new Intl.DateTimeFormat(locale, {
                dateStyle: 'full',
              }).format(new Date(record.activatedAt))
            );
          }}
        />
        <Column
          title="Cell Usage (Last 30 days)"
          dataIndex="cellUsage"
          render={(text, record) => {
            let voice;
            if (
              record.last30DaysVoiceUsage &&
              record.last30DaysVoiceUsage.totalUsage > 0
            ) {
              voice = `Voice: ${record.last30DaysVoiceUsage.totalUsage} secs`;
            }
            let data;
            if (
              record.last30DaysDataUsage &&
              record.last30DaysDataUsage.totalUsage > 0
            ) {
              data = `Data: ${filesize(record.last30DaysDataUsage.totalUsage, {
                round: 1,
              })}`;
            }
            let sms;
            if (
              record.last30DaysSmsUsage &&
              record.last30DaysSmsUsage.totalUsage > 0
            ) {
              sms = `SMS: ${record.last30DaysSmsUsage.totalUsage} msgs`;
            }
            return (
              <div>
                {voice && (
                  <div>
                    <Text>{voice}</Text>
                  </div>
                )}
                {data && (
                  <div>
                    <Text>{data}</Text>
                  </div>
                )}
                {sms && (
                  <div>
                    <Text>{sms}</Text>
                  </div>
                )}
              </div>
            );
          }}
        /> */}
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={`joon-device/view/${record._id}/${
                    showCreate ? 'daily-stats' : 'settings'
                  }`}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              {showUpdate && (
                <Tooltip title="Edit">
                  <Link
                    to={{
                      pathname: `joon-device/edit/${record._id}`,
                      state: { mode: 'update' },
                    }}
                  >
                    <Button icon={<EditOutlined />} />
                  </Link>
                </Tooltip>
              )}
            </Space>
          )}
        />
      </Table>
      <EnterImeiModal
        visible={showImeiModal}
        setVisible={setShowImeiModal}
        onYes={(imei) => handleCreate(imei)}
      />
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
      `}</style>
    </CSPage>
  );
}

export default ListJoonDevices;
