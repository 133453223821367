import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { formatPhoneNumber } from '../../../../shared/utils';
import {
  createJoon3ContactMutation,
  updateJoon3ContactMutation,
} from './constants';
import ProfilePhotoFormItem from '../../../../components/ProfilePhotoFormItem';

const { Title, Text } = Typography;

const AddJoon3ContactModal = ({ visible, onSuccess, onCancel }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { joonDevice, existing, creating } = visible || {};

  useEffect(() => {
    setError(undefined);
    form.resetFields();
  }, [visible, form]);

  useEffect(() => {
    if (existing) {
      form.setFieldsValue({
        name: existing.name,
        phone: existing.phone,
        isDisplayed: existing.isDisplayed,
      });
    }
  }, [existing, form]);

  const contactId = existing ? existing?._id : creating?._id;

  const [createJoon3Contact] = useMutation(createJoon3ContactMutation);
  const [updateJoon3Contact] = useMutation(updateJoon3ContactMutation);

  const handleFinish = useCallback(
    async (_values) => {
      const { phone, ...values } = _values;
      let standardPhone = formatPhoneNumber(phone);
      setError(null);
      setLoading(true);
      try {
        if (existing && !existing.userId) {
          await updateJoon3Contact({
            variables: {
              contact: {
                _id: existing._id,
                tenantId: joonDevice.tenantId,
                phone: standardPhone,
                ...values,
              },
            },
          });
          notification.success({
            message: 'Success',
            description: 'Device contact updated',
          });
        } else if (existing) {
          await updateJoon3Contact({
            variables: {
              contact: {
                _id: existing._id,
                ...values,
              },
            },
          });
          notification.success({
            message: 'Success',
            description: 'Device contact updated',
          });
        } else if (creating) {
          await createJoon3Contact({
            variables: {
              contact: {
                _id: creating._id,
                deviceId: joonDevice._id,
                tenantId: joonDevice.tenantId,
                phone: standardPhone,
                ...values,
              },
            },
          });
          notification.success({
            message: 'Success',
            description: 'Device contact created',
          });
        }
        if (onSuccess) onSuccess();
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [
      joonDevice,
      onSuccess,
      creating,
      existing,
      createJoon3Contact,
      updateJoon3Contact,
    ],
  );

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          {creating ? 'Add ' : 'Edit '}Device Contact
        </Title>

        <Form onFinish={handleFinish} layout="vertical" form={form}>
          {creating || (existing && !existing.userId) ? (
            <>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  { required: true, message: 'This field is required' },
                  {
                    pattern:
                      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    message:
                      'Please enter a valid phone number (ex. 5124026225).',
                  },
                ]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item label="Profile Photo">
                <ProfilePhotoFormItem
                  loading={loading}
                  parent={existing || creating}
                  parentType="joon3-contact"
                  parentId={contactId}
                  name="profilePhotoId"
                  form={form}
                />
              </Form.Item>
            </>
          ) : null}

          <Form.Item
            name="isDisplayed"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>
              Check if this contact is known to the wearer and can be displayed
              on the watch.
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={onCancel}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Save Contact
              </Button>
            </div>
          </Form.Item>
        </Form>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default AddJoon3ContactModal;
