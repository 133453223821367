import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Space, Tooltip, Upload } from 'antd';
import React, { useCallback, useState } from 'react';
import AttachmentCard from './AttachmentCard';
import csv from 'papaparse';
import readXlsxFile from 'read-excel-file';

const CSV_FILE_TYPE = 'text/csv';
const XLSX_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

function UploadCSVOrXLSXButton({ onFileParsed, onDelete }) {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState();

  const doUpload = useCallback(
    async (obj) => {
      try {
        setError(null);
        setUploadLoading(true);
        if (obj.file.type === CSV_FILE_TYPE) {
          csv.parse(obj.file, {
            complete: function (results) {
              onFileParsed(results.data);
            },
          });
        } else if (obj.file.type === XLSX_FILE_TYPE) {
          const result = await readXlsxFile(obj.file);
          onFileParsed(result);
        }
        setFile(obj.file);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setUploadLoading(false);
    },
    [onFileParsed],
  );

  return (
    <Space direction="vertical" style={{ marginRight: 8 }}>
      {file && (
        <AttachmentCard
          key={file.uid}
          onUploadDelete={() => {
            setFile(undefined);
            onDelete();
          }}
          fileName={file.name}
          fileSize={file.size}
        />
      )}
      {!file && (
        <Upload
          className="profile-pic-upload"
          showUploadList={false}
          listType="picture"
          customRequest={doUpload}
          accept={`${CSV_FILE_TYPE}, ${XLSX_FILE_TYPE}`}
        >
          {error && (
            <Tooltip title={error}>
              <Button danger icon={<ExclamationCircleOutlined />}>
                Failed
              </Button>
            </Tooltip>
          )}
          {!error && (
            <Button
              disabled={uploadLoading}
              icon={uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            >
              {uploadLoading ? 'Processing ...' : 'Upload CSV or XLSX'}
            </Button>
          )}
        </Upload>
      )}
    </Space>
  );
}

export default UploadCSVOrXLSXButton;
