import { Card, Space, Typography, notification } from 'antd';
import React, { useEffect } from 'react';
import CSPage from '../components/CSPage';
import CSPageHeader from '../components/CSPageHeader';
import { updateTenantAction } from '../redux-store/tenants-store';
import useOne from '../shared/use-one';
import useProfile from '../shared/use-profile';
import CallCenterSettings from './tenants/CallCenterSettings';
import { myTenantQuery } from './tenants/constants';
import { CallCenterCos } from './joonDevices/ViewJoonDevice/settings-v2/constants';
import { HomeOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

function Dashboard() {
  const profile = useProfile();

  const [notificationApi, contextHolder] = notification.useNotification();
  const fetchResult1 = useOne(
    myTenantQuery,
    (data) => data && data.myTenant,
    updateTenantAction,
    'tenants',
    profile.tenantId,
    profile.tenantId,
  );
  const { data: tenant, loading: loading1, error: error1 } = fetchResult1;
  useEffect(() => {
    if (error1) {
      notificationApi.error({
        description: 'There was an error fetching your tenant information',
        message: 'Error',
      });
    }
  }, [error1, notificationApi]);

  const callCenterName = CallCenterCos[tenant?.callCenterCo]?.label;

  return (
    <CSPage>
      {contextHolder}
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <HomeOutlined style={{ marginRight: 16 }} />
            Dashboard
          </Title>
        }
      />
      <Space
        className="body"
        size="large"
        direction="horizontal"
        style={{ flexWrap: 'wrap', alignItems: 'flex-start', marginBottom: 24 }}
      >
        <Space size="large" direction="vertical">
          <Card style={{ width: 360 }} bodyStyle={{ textAlign: 'center' }}>
            <Text>Welcome to Theora ESP</Text>
          </Card>
          {tenant && (
            <Card style={{ width: 360 }} bodyStyle={{ textAlign: 'center' }}>
              <Paragraph style={{ margin: 0 }}>{tenant.name}</Paragraph>
              <Paragraph style={{ margin: 0 }}>{callCenterName}</Paragraph>
            </Card>
          )}
        </Space>
        {profile.tenantId && <CallCenterSettings />}
      </Space>
      <style jsx>{`
        .body {
          display: flex;
        }
      `}</style>
    </CSPage>
  );
}

export default Dashboard;
