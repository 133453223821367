import { gql } from '@apollo/client';

export const TenantListAttributes = gql`
  {
    _id
    name
    notes
    logoId
    callCenterCo
    bootAnimation
    ccApiUsername
    ccApiPassword
    ccApiUrl
    callCenterNumber
    whitelistNumbers
    defaultHeartbeatIntervalMs
    defaultShakeToWake
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const TenantDetailedAttributes = gql`
  {
    _id
    name
    notes
    logoId
    primaryThumbnailUrl
    logo {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    callCenterCo
    bootAnimation
    ccApiUsername
    ccApiPassword
    ccApiUrl
    callCenterNumber
    whitelistNumbers
    defaultHeartbeatIntervalMs
    defaultShakeToWake
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const MyTenantAttributes = gql`
  {
    _id
    name
    logoId
    callCenterCo
    callCenterNumber
    whitelistNumbers
    defaultHeartbeatIntervalMs
    defaultShakeToWake
    createdAt
    updatedAt
  }
`;

export const tenantQuery = gql`
  query Tenant($_id: ID!) {
    tenant(_id: $_id) ${TenantDetailedAttributes}
  }
`;

export const myTenantQuery = gql`
  query MyTenant {
    myTenant ${MyTenantAttributes}
  }
`;

export const allTenantsQuery = gql`
  query AllTenants($first: Int, $after: String, $filters: TenantFilters, $sortBy: [SortBy]) {
    allTenants(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${TenantListAttributes}
      }
    }
  }
`;

export const deleteTenantMutation = gql`
  mutation deleteTenant($_id: ID!) {
    deleteTenant(_id: $_id) {
      deletedId
    }
  }
`;

export const createTenantMutation = gql`
  mutation createTenant($tenant: TenantCreateInput!) {
    createTenant(tenant: $tenant) {
      tenant ${TenantDetailedAttributes}
    }
  }
`;

export const updateTenantMutation = gql`
  mutation updateTenant($tenant: TenantUpdateInput!) {
    updateTenant(tenant: $tenant) {
      tenant ${TenantDetailedAttributes}
    }
  }
`;

export const updateMyTenantMutation = gql`
  mutation updateMyTenant($tenant: MyTenantUpdateInput!) {
    updateMyTenant(tenant: $tenant) {
      tenant ${MyTenantAttributes}
    }
  }
`;
