import {
  CompassOutlined,
  RocketOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Button, Tooltip, DatePicker } from 'antd';
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  getDateAnyDaysAgo,
  pointsOnACircle,
  removeSafeZoneMarkers,
  addSafeZoneMarkers,
  markerElement,
} from '../../../../shared/utils';
import useSafeState from '../../../../shared/use-safe-state';
import { LocationIcon } from '../../../../icons/icons';
import settings from '../../../../settings';
import useFilterGeofences from '../safe-zones/use-filter-geofences';
import { searchJoonEventsQuery, JoonDeviceEventTypes } from '../../constants';
import { useManyRemote } from '../../../../shared/use-many-remote';

const { RangePicker } = DatePicker;

const POPUP_OFFSETS = {
  top: [0, 0],
  bottom: [0, -35],
  'bottom-right': [0, -35],
  'bottom-left': [0, -35],
  left: [25, -35],
  right: [-25, -35],
};

const apiKey = 'qn31xnA19ybA0VrrF9XiinoAXWQQhv2c';

const DEFAULT_CENTER = { lon: -106.5348, lat: 38.7946 };

const DATE_FORMAT = 'DD-MM-YYYY';

const MapMessage = ({ message }) => {
  return (
    <div
      style={{
        color: settings.colors.textGray,
        fontSize: 24,
        fontWeight: 900,
        textAlign: 'center',
        paddingTop: 48,
        paddingBottom: 48,
      }}
    >
      {message}
    </div>
  );
};

function ViewDeviceLocationHistoryTab({ joonDevice, hasSafeZonePermissions }) {
  const todaysDate = new Date();
  const [mapLoaded, setMapLoaded, _mapLoaded] = useSafeState(0);
  const [isSatelliteMode, setIsSatelliteMode] = useState(false);
  const [showSafeZones, setShowSafeZones] = useState(true);
  const [timeframe, setTimeframe] = useState({
    start: getDateAnyDaysAgo(todaysDate, 1),
    end: todaysDate.getTime(),
    key: 'last-day',
  });
  const map = useRef(null);
  const source = useRef();
  const prev = useRef();
  const safeZoneMarkers = useRef({});
  const locale = useSelector((store) => store.locale, shallowEqual);
  const safeZones = useFilterGeofences({
    deviceId: joonDevice?._id,
  });
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const initialFilters = useMemo(() => {
    return {
      deviceId: joonDevice._id,
      from: timeframe.start,
      until: timeframe.end,
      type: JoonDeviceEventTypes.HB.key,
    };
  }, [joonDevice, timeframe]);

  const filters = useRef(initialFilters);
  const {
    error,
    loading,
    data: events,
    refetch,
  } = useManyRemote(
    searchJoonEventsQuery,
    (data) => data.searchJoonEvents,
    settings.querySize,
    filters.current,
    [{ key: 'timestampMs', order: 'DESC' }],
  );

  const latestLoc = useMemo(() => events[0]?.loc, [events]);

  const markersOnTheMap = useRef({});
  const refreshMarkers = useCallback(() => {
    if (map.current) {
      // empty map
      Object.keys(markersOnTheMap.current).forEach(function (_id) {
        markersOnTheMap.current[_id].remove();
        delete markersOnTheMap.current[_id];
      });
      // add markers back to map
      events.forEach(function (event) {
        const _id = event._id;
        if (!markersOnTheMap.current[_id]) {
          var newMarker = new tt.Marker({
            element: markerElement(),
            anchor: 'center',
          }).setLngLat([event.loc.lon, event.loc.lat]);
          newMarker.addTo(map.current);
          const popup = new tt.Popup({ offset: POPUP_OFFSETS })
            .setLngLat([event.loc.lon, event.loc.lat])
            .setHTML(
              `
                <h2>Location Event</h2>
                <p>Coodrinates: (${event.loc.lat}, ${event.loc.lon})</p>
                <p>Signal Strength: ${event.signalLevel} dBm</p>
                <p>Battery Level: ${event.batteryLevel}%</p>
                <p>Daily Steps: ${event.stepsSinceMidnight}</p>
                <p>Sent At: ${new Date(event.timestampMs)}</p>
              `,
            );
          newMarker.setPopup(popup);
          // TODO: in order to add accuracy circles use @turf/circle to create a GeoJSON circle and add it to the map here
          markersOnTheMap.current[_id] = newMarker;
        }
      });
    }
  }, [locale, events, map]);

  const loadMap = (center = DEFAULT_CENTER, zoom = 12) => {
    map.current = tt.map({
      key: apiKey,
      container: 'locationHistoryMapView',
      style: isSatelliteMode
        ? `https://api.tomtom.com/style/1/style/*?map=2/basic_street-satellite&poi=2/poi_dynamic-satellite&key=${apiKey}`
        : undefined,
      zoom,
      center: [center.lon, center.lat],
    });

    map.current.addControl(new tt.FullscreenControl());
    map.current.addControl(new tt.NavigationControl());
    map.current.on('load', () => {
      const interval = setInterval(() => {
        if (map.current.isStyleLoaded()) {
          setMapLoaded(_mapLoaded.current + 1);
          clearInterval(interval);
        }
      }, 100);
    });
    map.current.on('mouseenter', function () {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', function () {
      map.current.getCanvas().style.cursor = '';
    });
  };

  useEffect(() => {
    if (events.length) {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
      loadMap(latestLoc);
      refreshMarkers();
    } else {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, isSatelliteMode]);

  useEffect(() => {
    if (joonDevice && map.current && map.current.isStyleLoaded() && mapLoaded) {
      source.current = [{ device: joonDevice }];

      try {
        if (map.current.getSource('accuracy')) {
          map.current.removeLayer('accuracy');
          map.current.removeSource('accuracy');
        }
        safeZones.forEach((safeZone) => {
          if (map.current.getSource(`safeZones_${safeZone._id}`)) {
            map.current.removeLayer(`safeZones_${safeZone._id}`);
            map.current.removeSource(`safeZones_${safeZone._id}`);
          }
        });
      } catch (err) {
        console.error(err);
      }

      removeSafeZoneMarkers(safeZoneMarkers);
      if (showSafeZones) {
        safeZones.forEach((safeZone) =>
          map.current.addLayer({
            id: `safeZones_${safeZone._id}`,
            type: 'fill',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [
                    pointsOnACircle(
                      safeZone.center.lat,
                      safeZone.center.lon,
                      safeZone.radius,
                      100,
                    ),
                  ],
                },
              },
            },
            layout: {},
            paint: {
              'fill-color': settings.colors.red,
              'fill-opacity': 0.25,
              'fill-outline-color': settings.colors.red,
            },
          }),
        );
        addSafeZoneMarkers(map, safeZoneMarkers, safeZones, isSatelliteMode);
      }
      if (latestLoc) {
        map.current.addLayer({
          id: 'accuracy',
          type: 'fill',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  pointsOnACircle(
                    latestLoc.lat,
                    latestLoc.lon,
                    latestLoc.acc,
                    100,
                  ),
                ],
              },
            },
          },
          layout: {},
          paint: {
            'fill-color': settings.colors.blue,
            'fill-opacity': 0.5,
            'fill-outline-color': settings.colors.blue,
          },
        });
      }
    }
  }, [
    joonDevice,
    mapLoaded,
    locale,
    safeZones,
    showSafeZones,
    isSatelliteMode,
    latestLoc,
  ]);

  useEffect(() => {
    if (timeframe.start && timeframe.end && filters.current) {
      filters.current = {
        ...filters.current,
        from: timeframe.start,
        until: timeframe.end,
      };
      refetch();
    }
  }, [timeframe, refetch, filters]);

  useEffect(() => {
    if (map.current && latestLoc) {
      map.current.zoom = 12;
      map.current.flyTo({ center: [latestLoc.lon, latestLoc.lat] });
    }
  }, [map, latestLoc]);

  return (
    <>
      <div style={{ marginBottom: 16, zIndex: 10000 }}>
        <Button
          danger={timeframe.key === 'last-day'}
          onClick={() =>
            setTimeframe({
              start: getDateAnyDaysAgo(todaysDate, 1),
              end: todaysDate.getTime(),
              key: 'last-day',
            })
          }
        >
          Last Day
        </Button>
        <Button
          danger={timeframe.key === 'last-week'}
          style={{ marginLeft: 8 }}
          onClick={() =>
            setTimeframe({
              start: getDateAnyDaysAgo(todaysDate, 7),
              end: todaysDate.getTime(),
              key: 'last-week',
            })
          }
        >
          Last Week
        </Button>
        <Tooltip
          title={
            <RangePicker
              onOk={(evt) => {
                evt &&
                  evt[0] &&
                  evt[1] &&
                  setTimeframe({
                    start: new Date(evt[0].$d).getTime(),
                    end: new Date(evt[1].$d).getTime(),
                    key: 'custom',
                  });
              }}
              disabledDate={disabledDate}
              defaultValue={
                timeframe?.key === 'custom'
                  ? [dayjs(timeframe.start), dayjs(timeframe.end)]
                  : [dayjs().subtract(30, 'days'), dayjs()]
              }
              showTime
            />
          }
          color={'#fff'}
          placement={'bottomLeft'}
        >
          <Button style={{ marginLeft: 8 }} danger={timeframe.key === 'custom'}>
            Custom Timeframe
          </Button>
        </Tooltip>
      </div>
      <div id="locationHistoryMapView">
        {events.length && !error && !loading ? (
          <div className="map-options">
            {/*  */}
            {isSatelliteMode ? (
              <Button
                icon={<CompassOutlined />}
                onClick={() => {
                  prev.current = null;
                  source.current = null;
                  setMapLoaded(0);
                  setIsSatelliteMode(false);
                }}
              >
                Normal
              </Button>
            ) : (
              <Button
                icon={<RocketOutlined />}
                onClick={() => {
                  prev.current = null;
                  source.current = null;
                  setMapLoaded(0);
                  setIsSatelliteMode(true);
                }}
              >
                Satellite
              </Button>
            )}
            <Button
              icon={<LocationIcon />}
              style={{ marginLeft: 8 }}
              onClick={() => {
                if (latestLoc) {
                  map.current?.flyTo({
                    center: [latestLoc.lon, latestLoc.lat],
                    zoom: 15,
                  });
                }
              }}
            >
              Center
            </Button>
            {hasSafeZonePermissions && (
              <Button
                icon={<SafetyOutlined />}
                style={{ marginLeft: 8 }}
                onClick={() => setShowSafeZones(!showSafeZones)}
              >
                Toggle Safe Zones
              </Button>
            )}
          </div>
        ) : null}
        {!events.length && !error && !loading && (
          <MapMessage message="There is no location history information within the selected timeframe." />
        )}
        {!events.length && !error && loading && (
          <MapMessage message="Loading location history." />
        )}
        {!events.length && error && !loading && (
          <MapMessage message="There was an error loading this device's location history." />
        )}
      </div>
      <style jsx global>{`
        #locationHistoryMapView {
          min-height: 400px;
          height: 75vh;
          position: relative;
        }
        .map-options {
          position: absolute;
          z-index: 1000;
          top: 16px;
          left: 16px;
        }
      `}</style>
    </>
  );
}

export default ViewDeviceLocationHistoryTab;
