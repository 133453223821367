import {
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  LoginOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useProfile from '../../../shared/use-profile';
import { getAdminSignInAsLinkMutation, searchUsersQuery } from '../constants';

import { useMutation } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import settings from '../../../settings';
import copy from 'copy-to-clipboard';
import Highlighter from 'react-highlight-words';
import { useManyRemote } from '../../../shared/use-many-remote';
import useSafeState from '../../../shared/use-safe-state';
import SelectRole from '../../roles/SelectRole';
import SelectTenant from '../../tenants/SelectTenant';
import { generateUuid } from '../../../shared/utils';
import useIsSuperAdmin from '../../joonDevices/ViewJoonDevice/use-is-super-admin';
import { sendUserExportEmailQuery } from './constants';

const { Title, Text } = Typography;
const { Option } = Select;

function ListUsers() {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const [showFilters, setShowFilters] = useState(false);
  const profile = useProfile();
  const showTenantsPage = useMemo(() => {
    return profile?.role?.permissions?.includes('list-tenants:all');
  }, [profile]);
  const isSuperAdmin = useIsSuperAdmin();
  const client = useApolloClient();
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({});
  const [searchWords, setSearchWords, _searchWords] = useSafeState({});
  const searchInput = useRef(null);

  const handleCreate = useCallback(() => {
    const userId = generateUuid();
    history.push(`user/edit/${userId}`, { mode: 'create' });
  }, [history]);

  const handleUpdate = useCallback(
    (userId) => () => {
      history.push(`user/edit/${userId}`, { mode: 'update' });
    },
    [history],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: users,
    refetch,
    search: userSearch,
    hasNextPage,
    next,
  } = useManyRemote(
    searchUsersQuery,
    (data) => data.searchUsers,
    settings.querySize,
    filters.current,
    sortBy.current,
  );

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const [copying, setCopying] = useState(false);
  const [getAdminSignInAsLink] = useMutation(getAdminSignInAsLinkMutation);
  const handleSignInAs = useCallback(
    async (userId) => {
      setCopying(true);
      try {
        const result = await getAdminSignInAsLink({
          variables: { userId },
        });
        if (result && result.data && result.data.getAdminSignInAsLink) {
          copy(result.data.getAdminSignInAsLink);
          message.success('Sign-In-As Link Copied!');
        } else {
          throw new Error('Failed!');
        }
      } catch (err) {
        console.error(err);
        message.error(err.message);
      }
      setCopying(false);
    },
    [getAdminSignInAsLink],
  );

  const [form] = Form.useForm();
  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        userSearch(changed.search);
      }
    },
    [userSearch],
  );

  const onFiltersChange = useCallback((changed) => {
    filters.current = {
      ...filters.current,
      ...changed,
    };

    sortBy.current = [
      { key: '_score', order: 'DESC' },
      { key: sortedBy, order: sortOrder },
    ];

    refetch();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchWords({ ..._searchWords.current, [dataIndex]: selectedKeys[0] });
    filters.current = {
      ...filters.current,
      [dataIndex]: selectedKeys[0],
    };
    sortBy.current = [
      { key: '_score', order: 'DESC' },
      { key: sortedBy, order: sortOrder },
    ];
    refetch();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchWords({ ...searchWords.current, [dataIndex]: undefined });
  };

  const getColumnSearchProps = (dataIndex, hint) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        {hint && (
          <div style={{ color: settings.colors.textGray, marginBottom: 12 }}>
            {hint}
          </div>
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <div style={{ width: 60 }} />
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? settings.colors.red : undefined,
          fontSize: filtered ? 16 : undefined,
        }}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchWords[dataIndex] !== undefined ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: settings.colors.yellow,
            padding: 0,
            borderRadius: 2,
          }}
          searchWords={[searchWords[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleExportUsers = async () => {
    try {
      const response = await client.query({
        query: sendUserExportEmailQuery,
      });
      console.log(response);
      if (response.data.sendUserExportEmail !== 'Success')
        throw Error('Oh No! Something went wrong!');
      notification.success({
        message: 'Success',
        description:
          'You will receive an email with the exported users shortly.',
      });
    } catch {
      notification.error({
        message: 'Error',
        description:
          'There was an error while trying to export the users. Please try again.',
      });
    }
  };

  return (
    <CSPage title="Users" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <UsergroupAddOutlined style={{ marginRight: 16 }} />
            Users
          </Title>
        }
        topActions={[
          <Button
            key="add"
            type="link"
            onClick={handleCreate}
            icon={<PlusCircleOutlined />}
            size="large"
          >
            Add a new user
          </Button>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 16,
          }}
        >
          <Form
            layout="vertical"
            form={form1}
            style={{ maxWidth: 500, minWidth: 300 }}
            onValuesChange={onSearchChange}
          >
            <Tooltip title="Search Username, Name, Email, Role, Tenant">
              <Form.Item name="search" style={{ marginBottom: 16 }}>
                <Input placeholder="Search" suffix={<SearchOutlined />} />
              </Form.Item>
            </Tooltip>
          </Form>
          <Button
            icon={<FilterOutlined />}
            onClick={() => setShowFilters((prev) => !prev)}
          >
            {showFilters ? 'Less Filters' : 'More Filters'}
          </Button>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          {isSuperAdmin && (
            <Button icon={<FileZipOutlined />} onClick={handleExportUsers}>
              Export Users
            </Button>
          )}
          <Button icon={<ReloadOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </div>
      </div>
      {showFilters && (
        <div style={{ display: 'flex' }}>
          <Form
            layout="vertical"
            form={form2}
            style={{ maxWidth: 500, minWidth: 300 }}
            onValuesChange={onFiltersChange}
          >
            <Form.Item label="Tenant" name="tenantId" rules={[]}>
              <SelectTenant disabled={!users.length && loading} allowClear />
            </Form.Item>
            <Form.Item label="Role" name="roleId" rules={[]}>
              <SelectRole disabled={!users.length && loading} allowClear />
            </Form.Item>
          </Form>
        </div>
      )}
      <Table
        dataSource={users}
        loading={!users.length && loading}
        pagination={{ ...pagination, total: users.length }}
        onChange={handleTableChange}
        style={{ width: '100%', marginTop: 16 }}
        rowKey="_id"
      >
        <Column
          title="Username"
          dataIndex="username"
          key="username"
          {...getColumnSearchProps('username')}
        />
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          {...getColumnSearchProps('name')}
        />
        <Column
          title="Email"
          dataIndex="email"
          key="email"
          {...getColumnSearchProps('email')}
        />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          render={(text, record) => record.role && record.role.name}
        />
        <Column
          title="Tenant"
          dataIndex="tenantId"
          //key="tenant"
          render={(text, record) => record.tenantId && record.tenantId.name}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Link to={`/user/view/${record._id}/devices`}>
                <Tooltip title="View">
                  <Button icon={<EyeOutlined />} />
                </Tooltip>
              </Link>
              <Tooltip title="Edit">
                <Button
                  onClick={handleUpdate(record._id)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="sign-in-as"
                      icon={<LoginOutlined />}
                      onClick={() => handleSignInAs(record._id)}
                    >
                      Copy Sign-In-As Link
                    </Menu.Item>
                  </Menu>
                }
                trigger="click"
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </Space>
          )}
        />
      </Table>
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
      `}</style>
    </CSPage>
  );
}

export default ListUsers;
