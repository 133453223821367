import { List, Typography } from 'antd';

import settings from '../../../settings';

const { Text } = Typography;

function DisplayField({ title, value, component }) {
  return (
    <List.Item>
      <div>
        <Text style={{ color: settings.colors.primary, fontSize: 14 }}>
          {title}
        </Text>
      </div>
      <div>
        {component ? component : <Text style={{ fontSize: 14 }}>{value}</Text>}
      </div>
    </List.Item>
  );
}

export default DisplayField;
