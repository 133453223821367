import {
  CompassOutlined,
  RocketOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { shallowEqual, useSelector } from 'react-redux';
import useSafeState from '../../../../shared/use-safe-state';
import { LocationIcon } from '../../../../icons/icons';
import settings from '../../../../settings';
import useFilterGeofences from '../safe-zones/use-filter-geofences';
import {
  compareObjects,
  pointsOnACircle,
  removeSafeZoneMarkers,
  addSafeZoneMarkers,
  markerElement,
} from '../../../../shared/utils';

function refreshMarkers(map, markersOnTheMap, locale, devices) {
  Object.keys(markersOnTheMap.current).forEach(function (_id) {
    markersOnTheMap.current[_id].remove();
    delete markersOnTheMap.current[_id];
  });
  devices?.forEach((device) => {
    const loc = device.now.loc;
    var newMarker = new tt.Marker({
      element: markerElement(),
      anchor: 'bottom',
    }).setLngLat([loc.lon, loc.lat]);
    newMarker.addTo(map.current);
    markersOnTheMap.current[device.device._id] = newMarker;
  });
}

function ViewDeviceMapTab({ joonDevice, joonNow, hasSafeZonePermissions }) {
  const [mapLoaded, setMapLoaded, _mapLoaded] = useSafeState(0);
  const [isSatelliteMode, setIsSatelliteMode] = useState(false);
  const [showSafeZones, setShowSafeZones] = useState(true);
  const map = useRef(null);
  const source = useRef();
  const prev = useRef();

  const markersOnTheMap = useRef({});
  const safeZoneMarkers = useRef({});
  const locale = useSelector((store) => store.locale, shallowEqual);
  const safeZones = useFilterGeofences({
    deviceId: joonDevice?._id,
  });

  useEffect(() => {
    if (joonNow?.loc) {
      const apiKey = 'qn31xnA19ybA0VrrF9XiinoAXWQQhv2c';

      map.current = tt.map({
        key: apiKey,
        container: 'mapView',
        style: isSatelliteMode
          ? `https://api.tomtom.com/style/1/style/*?map=2/basic_street-satellite&poi=2/poi_dynamic-satellite&key=${apiKey}`
          : undefined,
        zoom: joonNow?.loc ? 15 : undefined,
        center: joonNow?.loc ? [joonNow.loc.lon, joonNow.loc.lat] : undefined,
      });

      map.current.addControl(new tt.FullscreenControl());
      map.current.addControl(new tt.NavigationControl());
      map.current.on('load', () => {
        const interval = setInterval(() => {
          if (map.current.isStyleLoaded()) {
            setMapLoaded(_mapLoaded.current + 1);
            clearInterval(interval);
          }
        }, 100);
      });
      map.current.on('mouseenter', 'clusters', function () {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'clusters', function () {
        map.current.getCanvas().style.cursor = '';
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSatelliteMode, locale, joonNow]);

  useEffect(() => {
    if (
      joonDevice &&
      joonNow &&
      map.current &&
      map.current.isStyleLoaded() &&
      mapLoaded &&
      !compareObjects(prev.current?.loc !== joonNow?.loc)
    ) {
      prev.current = joonNow;
      source.current = [{ device: joonDevice, now: joonNow }];

      try {
        if (map.current.getSource('accuracy')) {
          map.current.removeLayer('accuracy');
          map.current.removeSource('accuracy');
        }
        safeZones.forEach((safeZone) => {
          if (map.current.getSource(`safeZones_${safeZone._id}`)) {
            map.current.removeLayer(`safeZones_${safeZone._id}`);
            map.current.removeSource(`safeZones_${safeZone._id}`);
          }
        });
      } catch (err) {
        console.error(err);
      }

      removeSafeZoneMarkers(safeZoneMarkers);
      if (showSafeZones) {
        safeZones.forEach((safeZone) =>
          map.current.addLayer({
            id: `safeZones_${safeZone._id}`,
            type: 'fill',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [
                    pointsOnACircle(
                      safeZone.center.lat,
                      safeZone.center.lon,
                      safeZone.radius,
                      100,
                    ),
                  ],
                },
              },
            },
            layout: {},
            paint: {
              'fill-color': settings.colors.red,
              'fill-opacity': 0.25,
              'fill-outline-color': settings.colors.red,
            },
          }),
        );
        addSafeZoneMarkers(map, safeZoneMarkers, safeZones, isSatelliteMode);
      }
      map.current.addLayer({
        id: 'accuracy',
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                pointsOnACircle(
                  joonNow.loc.lat,
                  joonNow.loc.lon,
                  joonNow.loc.acc,
                  100,
                ),
              ],
            },
          },
        },
        layout: {},
        paint: {
          'fill-color': settings.colors.blue,
          'fill-opacity': 0.5,
          'fill-outline-color': settings.colors.blue,
        },
      });
      refreshMarkers(map, markersOnTheMap, locale, source.current);
    }
  }, [
    joonDevice,
    joonNow,
    mapLoaded,
    locale,
    safeZones,
    showSafeZones,
    isSatelliteMode,
  ]);

  return (
    <>
      <div id="mapView">
        {joonNow?.loc ? (
          <div className="toggle-sat">
            {isSatelliteMode ? (
              <Button
                icon={<CompassOutlined />}
                onClick={() => {
                  prev.current = null;
                  source.current = null;
                  setMapLoaded(0);
                  setIsSatelliteMode(false);
                }}
              >
                Normal
              </Button>
            ) : (
              <Button
                icon={<RocketOutlined />}
                onClick={() => {
                  prev.current = null;
                  source.current = null;
                  setMapLoaded(0);
                  setIsSatelliteMode(true);
                }}
              >
                Satellite
              </Button>
            )}
            <Button
              icon={<LocationIcon />}
              style={{ marginLeft: 8 }}
              onClick={() => {
                if (joonNow.loc) {
                  map.current?.flyTo({
                    center: [joonNow.loc.lon, joonNow.loc.lat],
                    zoom: 15,
                  });
                }
              }}
            >
              Center
            </Button>
            {hasSafeZonePermissions && (
              <Button
                icon={<SafetyOutlined />}
                style={{ marginLeft: 8 }}
                onClick={() => setShowSafeZones(!showSafeZones)}
              >
                Toggle Safe Zones
              </Button>
            )}
          </div>
        ) : (
          <div
            style={{
              color: settings.colors.textGray,
              fontSize: 24,
              fontWeight: 900,
              textAlign: 'center',
              paddingTop: 48,
              paddingBottom: 48,
            }}
          >{`This device doesn't have a location yet.`}</div>
        )}
      </div>
      <style jsx global>{`
        #mapView {
          min-height: 300px;
          height: 70vh;
          position: relative;
        }
        .toggle-sat {
          position: absolute;
          z-index: 10000;
          top: 16px;
          left: 16px;
        }
      `}</style>
    </>
  );
}

export default ViewDeviceMapTab;
