import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Space,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createUserAction,
  updateUserAction,
} from '../../../redux-store/users-store';
import useIsCreating from '../../../shared/use-is-creating';
import SelectRole from '../../roles/SelectRole';
import ChangeUserPasswordModal from '../ChangeUserPasswordModal';
import {
  userQuery,
  updateUserMutation,
  createUserMutation,
} from '../constants';
import DeleteUserModal from '../DeleteUserModal';
import useOne from '../../../shared/use-one';
import SelectTenant from '../../tenants/SelectTenant';
import { formatPhoneNumberForStorage } from '../../../shared/utils';

const { Text } = Typography;

function EditUser() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [changingPassword, setChangingPassword] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [updateUser] = useMutation(updateUserMutation);
  const [createUser] = useMutation(createUserMutation);
  const dispatch = useDispatch();

  const { _id } = useParams();
  const queryId = useMemo(() => {
    return Math.round(Math.random() * 10000);
  }, []);

  const fetchResult = useOne(
    userQuery,
    (data) => data && data.user,
    updateUserAction,
    'users',
    _id,
    queryId,
  );

  const [form] = Form.useForm();
  const labelInput = useRef(null);
  const [user, setUser] = useState();

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (!fetchResult.loading && !onlyOnce.current && fetchResult.data) {
      setUser(fetchResult.data);
      onlyOnce.current = true;
    }
  }, [fetchResult, loading]);

  useEffect(() => {
    if (user) {
      const { ...otherFields } = user;
      form.setFieldsValue(otherFields);
    }
  }, [form, user]);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const {
        phone,
        confirm,
        devices = [],
        permissionGroupId,
        acceptedEulaVersion: eulaVersion,
        ...otherValues
      } = _values;
      let standardPhone;
      if (phone) {
        standardPhone = formatPhoneNumberForStorage(phone);
      } else {
        standardPhone = null;
      }
      const acceptedEulaVersion = eulaVersion || null;
      const username = otherValues.email;
      try {
        if (user) {
          const result = await updateUser({
            variables: {
              user: {
                _id,
                ...otherValues,
                phone: standardPhone,
                acceptedEulaVersion,
                username,
              },
            },
          });
          dispatch(updateUserAction(result.data.updateUser.user));
          notification.success({
            message: 'Saved',
            description: 'User updated successfully',
          });
        } else {
          const result = await createUser({
            variables: {
              user: {
                _id,
                ...otherValues,
                phone: standardPhone,
                acceptedEulaVersion,
                username,
              },
            },
          });
          dispatch(createUserAction(result.data.createUser.user));
          notification.success({
            message: 'Saved',
            description: 'User created successfully',
          });
        }
        history.push(`/user/view/${_id}/devices`);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [user, _id, history, createUser, updateUser, dispatch],
  );

  const { title, showDelete } = useIsCreating(
    location,
    user,
    loading,
    'Create New User',
    'Edit User',
  );

  const serverError = error;

  return (
    <>
      <CSPage title={title}>
        <CSPageHeader
          title={title}
          backActions={[
            <Link to="/users" key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to Users
              </Button>
            </Link>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          style={{ maxWidth: 500 }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a name' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                message: 'Please enter a valid phone number (ex. 5124026225).',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Role"
            name="roleId"
            rules={[{ required: true, message: 'Please select a role' }]}
          >
            <SelectRole disabled={loading || fetchResult.loading} />
          </Form.Item>
          <Form.Item label="Tenant" name="tenantId">
            <SelectTenant disabled={loading || fetchResult.loading} />
          </Form.Item>
          {!user && (
            <>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input a password',
                  },
                ]}
                hasFeedback
              >
                <Input.Password disabled={loading} />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!',
                      );
                    },
                  }),
                ]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>
            </>
          )}

          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          <Form.Item
            name="emailVerified"
            rules={[]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>Email Verified</Checkbox>
          </Form.Item>
          <Form.Item
            label="Accepted Eula Version"
            name="acceptedEulaVersion"
            rules={[]}
          >
            <InputNumber min={0} step={1} disabled={loading} />
          </Form.Item>
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                key="cancel"
                onClick={() => history.push('/users')}
                htmlType="button"
                type="text"
                size="small"
                disabled={loading}
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: -7 }}
              >
                Cancel
              </Button>
              {showDelete && (
                <Button
                  key="changePassword"
                  onClick={() => setChangingPassword(user)}
                  htmlType="button"
                  disabled={loading}
                  icon={<LockOutlined />}
                >
                  Change Password
                </Button>
              )}
              <Button
                key="send"
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Save User
              </Button>
            </Space>
          </Form.Item>
          {serverError && (
            <div style={{ marginTop: 16 }}>
              <Text type="danger">{serverError}</Text>
            </div>
          )}
          {showDelete && (
            <>
              <Divider />
              <div className="delete-box">
                <Space align="center">
                  <Button
                    onClick={() => setDeleting(user)}
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                  >
                    Delete User
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Form>
        <div style={{ minHeight: 300 }} />
        <DeleteUserModal setUser={setDeleting} user={deleting} />
        <ChangeUserPasswordModal
          user={changingPassword}
          setUser={setChangingPassword}
          updateUser={updateUser}
          getResult={(result) =>
            result.data && result.data.updateUser && result.data.updateUser.user
          }
        />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditUser;
