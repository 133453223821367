import React, { useCallback, useState } from 'react';
import { Typography, Button, Card, Space, Spin, Table, Tooltip } from 'antd';

import CSPage from '../../../components/CSPage';
import {
  KeyOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { generateUuid } from '../../../shared/utils';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../settings';
import Column from 'antd/lib/table/Column';
import CSPageHeader from '../../../components/CSPageHeader';
import { allApiKeysQuery } from '../constants';
import { readApiKeysAction } from '../../../redux-store/api-keys-store';
import useMany from '../../../shared/use-many';

const { Title, Text } = Typography;

function ListApiKeys() {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: apiKeys,
    refetch,
  } = useMany(
    allApiKeysQuery,
    (data) => data.allApiKeys,
    readApiKeysAction,
    'apiKeys',
    ['label', 'desc', 'issuer', 'audience'],
    settings.querySize,
    {},
    (a, b) => a.label.localeCompare(b.label, locale),
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <KeyOutlined style={{ marginRight: 16 }} />
            API Keys
          </Title>
        }
        topActions={[
          <Link
            key="add"
            to={{
              pathname: `api-key/edit/${generateUuid()}`,
              state: { mode: 'create' },
            }}
          >
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new API key
            </Button>
          </Link>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Table
        dataSource={apiKeys}
        loading={!apiKeys.length && loading}
        pagination={{ ...pagination, total: apiKeys.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="Label" dataIndex="label" key="label" />
        <Column title="Description" dataIndex="desc" key="desc" />
        <Column
          title="Role"
          dataIndex="role"
          render={(text, record) => {
            return record.role.name;
          }}
        />
        <Column
          title="Created"
          dataIndex="createdAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.createdAt));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {/* <Tooltip title="View">
                <Button
                  onClick={() => history.push(`/deployment/${record.serialNo}`)}
                  icon={<EyeOutlined />}
                />
              </Tooltip> */}
              <Tooltip title="Edit">
                <Link
                  key="add"
                  to={{
                    pathname: `api-key/edit/${record._id}`,
                  }}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
      `}</style>
    </CSPage>
  );
}

export default ListApiKeys;
