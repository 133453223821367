import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Select, Typography } from 'antd';
import React from 'react';
import SelectTenant from '../../tenants/SelectTenant';
import { DayFilters } from './use-filter-map-devices';

const { Text } = Typography;

function SelectDaysFilter({ value, onChange, ...other }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Filter by days since last update ..."
      {...other}
    >
      {Object.values(DayFilters).map((field) => (
        <Select.Option key={field.key} value={field.key}>
          {field.label}
        </Select.Option>
      ))}
    </Select>
  );
}

function DeviceFilters({
  filtered,
  unfiltered,
  searchDevices,
  setFilters,
  showTenantFilter,
}) {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onValuesChange={(changed, values) => {
        if (changed.search !== undefined) {
          searchDevices(changed.search);
        } else {
          setFilters(values);
        }
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <Form.Item name="search" style={{ marginRight: 16 }}>
          <Input
            placeholder="Search"
            suffix={<SearchOutlined />}
            style={{ maxWidth: 500, minWidth: 300 }}
          />
        </Form.Item>
        {showTenantFilter && (
          <Form.Item placeholder="Tenant" name="tenantId" rules={[]}>
            <SelectTenant allowClear style={{ maxWidth: 500, minWidth: 300 }} />
          </Form.Item>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <Form.Item name="daysFilter" initialValue="ALL">
          <SelectDaysFilter style={{ maxWidth: 500, minWidth: 300 }} />
        </Form.Item>
        <div style={{ marginRight: 'auto' }} />
        <Form.Item>
          <Text>{`${filtered.length} / ${unfiltered.length} devices`}</Text>
        </Form.Item>
      </div>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.daysFilter !== curValues.daysFilter
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('daysFilter') == 'MORE' && (
            <div style={{ display: 'flex' }}>
              <Form.Item style={{ marginRight: 8 }}>
                Updated more than
              </Form.Item>
              <Form.Item
                name="moreThanHours"
                initialValue={0}
                style={{ paddingBottom: 8, marginRight: 8 }}
              >
                <InputNumber min={0} step={1} />
              </Form.Item>
              <Form.Item style={{ marginRight: 16 }}>
                hours and less than
              </Form.Item>
              <Form.Item
                name="lessThanHours"
                initialValue={720}
                style={{ paddingBottom: 8, marginRight: 8 }}
              >
                <InputNumber min={0} step={1} />
              </Form.Item>
              <Form.Item>hours ago.</Form.Item>
              <div style={{ marginRight: 'auto' }} />
            </div>
          )
        }
      </Form.Item>
      <style jsx global>{`
        .ant-select-dropdown {
          z-index: 10001;
        }
      `}</style>
    </Form>
  );
}

export default DeviceFilters;
