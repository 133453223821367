import { Typography } from 'antd';
import React from 'react';
import { PieChartOutlined } from '@ant-design/icons';

import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import NightlyReportsTable from './nightly-reports/NightlyReportsTable';

const { Title, Text } = Typography;

function ReportDashboardPage() {
  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <PieChartOutlined style={{ marginRight: 16 }} />
            Report Dashboard
          </Title>
        }
      />
      <NightlyReportsTable />
      <style jsx>{`
        .body {
          display: flex;
        }
      `}</style>
    </CSPage>
  );
}

export default ReportDashboardPage;
