import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteApiKeyAction } from '../../redux-store/api-keys-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import useIsTenantAdmin from '../joonDevices/ViewJoonDevice/use-is-tenant-admin';

const deleteApiKeyMutation = gql`
  mutation deleteApiKey($_id: ID!) {
    deleteApiKey(_id: $_id) {
      deletedId
    }
  }
`;

const deleteTenantApiKeyMutation = gql`
  mutation deleteTenantApiKey($_id: ID!) {
    deleteTenantApiKey(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteApiKeyModal({
  apiKey,
  onFinish,
  onCancel,
  queryId = 'default',
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteApiKey] = useMutation(deleteApiKeyMutation);
  const [deleteTenantApiKey] = useMutation(deleteTenantApiKeyMutation);
  const dispatch = useDispatch();
  const isTenantAdmin = useIsTenantAdmin();
  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      isTenantAdmin
        ? await deleteTenantApiKey({
            variables: {
              _id: apiKey._id,
            },
          })
        : await deleteApiKey({
            variables: {
              _id: apiKey._id,
            },
          });
      dispatch(deleteApiKeyAction(apiKey._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'Api key deleted successfully',
      });
      setTimeout(() => {
        setLoading(false);
        onFinish();
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [apiKey, deleteApiKey, onFinish, dispatch, queryId]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="Delete Api Key"
      question={`Are you sure you want to delete this api Key (${
        apiKey && apiKey.label
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!apiKey}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteApiKeyModal;
