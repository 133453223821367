import { useMemo } from 'react';
import useProfile from '../../../shared/use-profile';

function useIsSuperAdmin() {
  const profile = useProfile();
  const isSuperAdmin = useMemo(() => {
    return !!profile?.role?.permissions?.includes('ui:superadmin');
  }, [profile]);
  return isSuperAdmin;
}

export default useIsSuperAdmin;
