import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Typography,
  notification,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createTenantAction,
  updateTenantAction,
} from '../../../redux-store/tenants-store';
import useIsCreating from '../../../shared/use-is-creating';
import useOne from '../../../shared/use-one';
import { BootAnimationScreens } from '../../joonDevices/constants';
import ProfilePhotoFormItem from '../../../components/ProfilePhotoFormItem';
import DeleteTenantModal from '../DeleteTenantModal';
import {
  createTenantMutation,
  tenantQuery,
  updateTenantMutation,
} from '../constants';

const { Text, Paragraph } = Typography;

// function SelectCallCenterCo({ value, onChange, ...other }) {
//   return (
//     <Select
//       value={value}
//       onChange={onChange}
//       placeholder="Select a call center"
//       allowClear
//       {...other}
//     >
//       {Object.values(CallCenterCos).map((field) => (
//         <Select.Option key={field.key} value={field.key}>
//           {field.label}
//         </Select.Option>
//       ))}
//     </Select>
//   );
// }

function SelectBootAnimation({ value, onChange, ...other }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select a call center"
      allowClear
      {...other}
    >
      {BootAnimationScreens.map((field) => (
        <Select.Option key={field.key} value={field.key}>
          {field.label}
        </Select.Option>
      ))}
    </Select>
  );
}

function EditTenant() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [updateTenant] = useMutation(updateTenantMutation);
  const [createTenant] = useMutation(createTenantMutation);
  const dispatch = useDispatch();

  const { _id } = useParams();
  const queryId = useMemo(() => {
    return Math.round(Math.random() * 10000);
  }, []);

  const fetchResult = useOne(
    tenantQuery,
    (data) => data && data.tenant,
    updateTenantAction,
    'tenants',
    _id,
    queryId,
  );

  const [form] = Form.useForm();
  const labelInput = useRef(null);
  const [tenant, setTenant] = useState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (!fetchResult.loading && !onlyOnce.current && fetchResult.data) {
      setTenant(fetchResult.data);
      onlyOnce.current = true;
    }
  }, [fetchResult, loading]);

  useEffect(() => {
    if (tenant) {
      const { defaultHeartbeatIntervalMs, ...otherFields } = tenant;
      form.setFieldsValue({
        defaultHeartbeatIntervalMin: defaultHeartbeatIntervalMs
          ? defaultHeartbeatIntervalMs / 1000 / 60
          : 60,
        ...otherFields,
      });
    }
  }, [form, tenant]);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const handleBack = useCallback(() => history.goBack(), [history]);

  const onSubmit = useCallback(
    async (_values) => {
      const {
        // callCenterNumber: _callCenterNumber,
        // whitelistNumbers: _whitelistNumbers,
        // defaultHeartbeatIntervalMin,
        // defaultShakeToWake,
        ...values
      } = _values;
      setLoading(true);
      setError(null);
      try {
        // let callCenterNumber = null;
        // if (_callCenterNumber) {
        //   callCenterNumber = formatPhoneNumber(_callCenterNumber);
        // }
        // const whitelistNumbers = _whitelistNumbers
        //   ? formatMultiplePhoneNumbers(_whitelistNumbers)
        //   : null;
        // const defaultHeartbeatIntervalMs = Math.round(
        //   defaultHeartbeatIntervalMin * 60 * 1000,
        // );

        if (tenant) {
          const result = await updateTenant({
            variables: {
              tenant: {
                _id,
                ...values,
                // callCenterNumber,
                // whitelistNumbers,
                // defaultHeartbeatIntervalMs,
                // defaultShakeToWake: defaultShakeToWake ? true : false,
              },
            },
          });
          dispatch(updateTenantAction(result.data.updateTenant.tenant));
          notificationApi.success({
            message: 'Saved',
            description: 'Tenant updated successfully',
          });
        } else {
          const result = await createTenant({
            variables: {
              tenant: {
                _id,
                ...values,
                // callCenterNumber,
                // whitelistNumbers,
                // defaultHeartbeatIntervalMs,
                // defaultShakeToWake: defaultShakeToWake ? true : false,
              },
            },
          });
          dispatch(createTenantAction(result.data.createTenant.tenant));
          notificationApi.success({
            message: 'Saved',
            description: 'Tenant created successfully',
          });
        }
        history.push('/tenants');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [
      tenant,
      _id,
      history,
      createTenant,
      updateTenant,
      dispatch,
      notificationApi,
    ],
  );

  const { title, showDelete } = useIsCreating(
    location,
    tenant,
    loading,
    'Create New Tenant',
    'Edit Tenant',
  );

  return (
    <>
      {contextHolder}
      <CSPage>
        <CSPageHeader
          title={title}
          backActions={[
            <Link key="back" to="/tenants">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to Tenants
              </Button>
            </Link>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="editTenant"
          form={form}
          style={{ maxWidth: 500 }}
        >
          <Form.Item label="Logo">
            <ProfilePhotoFormItem
              loading={loading}
              parent={tenant}
              parentType="tenant"
              parentId={_id}
              name="logoId"
              fileType="tenant-logo"
              tags="tenantLogo"
              form={form}
              photoKey="logo"
            />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a name' }]}
          >
            <Input ref={labelInput} disabled={loading} />
          </Form.Item>
          {/* <Form.Item
            label="Call Center"
            name="callCenterCo"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <SelectCallCenterCo disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Boot Screen"
            name="bootAnimation"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <SelectBootAnimation disabled={loading} />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.callCenterCo !== curValues.callCenterCo
            }
            noStyle
          >
            {({ getFieldValue }) => (
              <>
                {[
                  CallCenterCos.AVANT_GUARD.key,
                  CallCenterCos.TEST.key,
                ].includes(getFieldValue('callCenterCo')) ? (
                  <Form.Item
                    label="Call Center Phone Number"
                    name="callCenterNumber"
                    rules={[
                      {
                        pattern:
                          /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message:
                          'Please enter a valid phone number (ex. 5124026225).',
                      },
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Paragraph>
                      The SOS Phone Number is specified per device
                    </Paragraph>
                  </Form.Item>
                )}
                <Form.Item
                  label="Whitelist Phone Numbers"
                  name="whitelistNumbers"
                  extra="Separate numbers by spaces or place one number on each line"
                  rules={[
                    validateMultiplePhoneNumbers,
                    {
                      required:
                        getFieldValue('callCenterCo') ===
                        CallCenterCos.AVANT_GUARD.key,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.TextArea disabled={loading} rows={4} />
                </Form.Item>
              </>
            )}
          </Form.Item>
          <Form.Item
            label="Default Heartbeat Interval (minutes)"
            name="defaultHeartbeatIntervalMin"
            extra="Default is 60 min"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            initialValue={60}
          >
            <InputNumber
              disabled={loading}
              min={0}
              step={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label="Default Shake To Wake Setting"
            name="defaultShakeToWake"
            extra="Default is Off"
            initialValue={false}
            valuePropName="checked"
          >
            <Switch checkedChildren="On" unCheckedChildren="Off" />
          </Form.Item> */}
          <Form.Item label="Notes" name="notes">
            <Input.TextArea disabled={loading} rows={4} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                key="cancel"
                onClick={handleBack}
                htmlType="button"
                type="text"
                size="small"
                disabled={loading}
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: -7 }}
              >
                Cancel
              </Button>
              <Button
                key="send"
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Save Tenant
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <div style={{ marginTop: 16 }}>
              <Text type="danger">{error}</Text>
            </div>
          )}
          {showDelete && (
            <>
              <Divider />
              <div className="delete-box">
                <Space align="center">
                  <Button
                    onClick={() => setDeleting(tenant)}
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                  >
                    Delete Tenant
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Form>
        <div style={{ minHeight: 300 }} />
        <DeleteTenantModal setTenant={setDeleting} tenant={deleting} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditTenant;
