import { gql } from '@apollo/client';

export const UserListAttributes = gql`
  {
    _id
    username
    roleId
    role {
      _id
      name
      desc
      permissions
    }
    tenantId
    email
    emailVerified
    acceptedEulaVersion
    name
    phone
    primaryThumbnailUrl
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const UserDetailedAttributes = gql`
  {
    _id
    username
    roleId
    role {
      _id
      name
      desc
      permissions
    }
    tenantId
    tenant {
      _id
      name
    }
    email
    emailVerified
    acceptedEulaVersion
    name
    phone
    # similar example of a calculated field being successfully queried
    profilePhoto {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    primaryThumbnailUrl
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const ProfileDetailedAttributes = gql`
  {
    _id
    username
    roleId
    role {
      _id
      name
      desc
      permissions
    }
    tenantId
    email
    name
    phone
    profilePhoto {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    primaryThumbnailUrl
  }
`;

export const userQuery = gql`
  query User($_id: ID!) {
    user(_id: $_id) ${UserDetailedAttributes}
  }
`;

export const myProfileQuery = gql`
  query MyProfile {
    myProfile {
      _id
      username
      roleId
      role {
        _id
        name
        desc
        permissions
      }
      tenantId
      email
      name
      phone
      profilePhoto {
        _id
        s3Key
        size
        filename
        contentType
        url
        thumbnail {
          url
        }
        images {
          sizeCode
          url
        }
      }
      primaryThumbnailUrl
    }
  }
`;

export const extractMyProfile = (data) => data && data.myProfile;

export const createUserMutation = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      user ${UserDetailedAttributes}
    }
  }
`;

export const updateUserMutation = gql`
  mutation ThisIsTheUpdateUserMutation($user: UserUpdateInput!) {
    updateUser(user: $user) {
      user ${UserDetailedAttributes}
    }
  }
`;

export const updateProfileMutation = gql`
  mutation updateProfile($profile: ProfileUpdateInput!) {
    updateProfile(Profile: $profile) {
      profile ${ProfileDetailedAttributes}
    }
  }
`;

export const getAdminSignInAsLinkMutation = gql`
  mutation getAdminSignInAsLink($userId: ID!) {
    getAdminSignInAsLink(userId: $userId)
  }
`;

export const editProfileMutation = gql`
  mutation EditProfilePage($profile: EditProfileInput!) {
    editProfile(profile: $profile) {
      profile ${ProfileDetailedAttributes}
    }
  }
`;

export const getFileUploadUrlMutation = gql`
  mutation GetFileUploadUrl($request: GetFileUploadUrlRequest!) {
    getFileUploadUrl(request: $request) {
      url
      fields
    }
  }
`;

export const searchUsersQuery = gql`
  query SearchUsers($first: Int, $after: String, $filters: UserFilters, $sortBy: [SortBy]) {
    searchUsers(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${UserListAttributes}
      }
    }
  }
`;

export const createFileMutation = gql`
  mutation CreateFile($file: CreateFileInput!) {
    createFile(file: $file) {
      file {
        _id
        desc
        s3Key
        size
        filename
        contentType
        tags
        url
        uploaded
        processed
        parentId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
