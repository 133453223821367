import { ApolloProvider } from '@apollo/client';
import { Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import client from './apollo-client';
import AppLoading from './components/AppLoading';
import MainRouter from './pages/main/MainRouter';
import AuthRouter from './pages/auth/AuthRouter';
import { AuthContextProvider } from './shared/auth-context';
import { useWindowDimensions } from './shared/window-dimensions';

// these extensions are needed for using dayjs with antdesign's RangePicker component (get an error without this fix here)
// im sorry, I think its ugly too :(
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { Text } = Typography;

const App = () => {
  useWindowDimensions();
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <AuthContextProvider>
      {(context) => (
        <>
          {context.state.isLoading ? (
            <AppLoading />
          ) : (
            <ApolloProvider client={client}>
              <Router>
                {context.state.user ? <MainRouter /> : <AuthRouter />}
              </Router>
            </ApolloProvider>
          )}
        </>
      )}
    </AuthContextProvider>
  );
};

export default App;
