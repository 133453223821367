import {
  CalendarOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CodeOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  KeyOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
  MessageOutlined,
  PieChartOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Layout, Menu, notification, Typography } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import longLogo from '../assets/long-logo-md.png';
import espLogo from '../assets/logo.png';
import logo from '../assets/logo.png';
import { AuthContext } from '../shared/auth-context';
import useProfile from '../shared/use-profile';

const { Sider } = Layout;
const { Title } = Typography;

function MainMenu({ currentItem, onMenuClick, permissions }) {
  const showAdminPages = useMemo(() => {
    if (permissions) {
      return permissions.includes('ui:view-all');
    }
    return false;
  }, [permissions]);
  const showMapPage = useMemo(() => {
    if (permissions) {
      return permissions.includes('ui:view-map');
    }
    return false;
  }, [permissions]);
  const showFirmwarePage = useMemo(() => {
    if (permissions) {
      return permissions.includes('list-fw-versions:all');
    }
    return false;
  }, [permissions]);
  const showTenantsPage = useMemo(() => {
    if (permissions) {
      return permissions.includes('list-tenants:all');
    }
    return false;
  }, [permissions]);
  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[currentItem]}
      onClick={onMenuClick}
      className="main-nav-menu"
    >
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <Link to="/home">Home</Link>
      </Menu.Item>
      {showAdminPages && (
        <Menu.Item key="report-dashboard" icon={<PieChartOutlined />}>
          <Link to="/report-dashboard/reports">Report Dashboard</Link>
        </Menu.Item>
      )}
      {showMapPage && (
        <Menu.Item key="map" icon={<GlobalOutlined />}>
          <Link to="/joon-devices/map">Map</Link>
        </Menu.Item>
      )}
      {showAdminPages && (
        <Menu.Item key="users" icon={<UsergroupAddOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
      )}
      {showAdminPages && (
        <Menu.Item key="roles" icon={<IdcardOutlined />}>
          <Link to="/roles">Roles</Link>
        </Menu.Item>
      )}
      {showTenantsPage && (
        <>
          <Menu.Item key="tenants" icon={<ShopOutlined />}>
            <Link to="/tenants">Tenants</Link>
          </Menu.Item>
        </>
      )}
      <Menu.Item key="joon-devices" icon={<ClockCircleOutlined />}>
        <Link to="/joon-devices">Theora Connect™ Devices</Link>
      </Menu.Item>
      {showAdminPages && (
        <>
          <Menu.Item key="joon-datasets" icon={<DatabaseOutlined />}>
            <Link to="/joon-datasets">Theora Connect™ Datasets</Link>
          </Menu.Item>
        </>
      )}
      {showFirmwarePage && (
        <Menu.Item key="fw-versions" icon={<CodeOutlined />}>
          <Link to="/fw-versions">Firmware Versions</Link>
        </Menu.Item>
      )}
      {showAdminPages ? (
        <Menu.Item key="api-keys" icon={<KeyOutlined />}>
          <Link to="/api-keys">API Keys</Link>
        </Menu.Item>
      ) : (
        <Menu.Item key="tenant-api-keys" icon={<KeyOutlined />}>
          <Link to="/tenant-api-keys">API Keys</Link>
        </Menu.Item>
      )}
      <Menu.Item key="webhooks" icon={<ApiOutlined />}>
        <Link to="/webhooks">Webhooks</Link>
      </Menu.Item>
      {/* <div className="spacer" />
      <Menu.Item key="sign-out" icon={<LogoutOutlined />}>
        Sign Out
      </Menu.Item> */}
    </Menu>
  );
}

function MainSider({ collapsed, setCollapsed, children }) {
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={80}
      onBreakpoint={(broken) => {
        setCollapsed(collapsed || broken);
      }}
      onCollapse={(_collapsed, type) => {
        if (type === 'clickTrigger') {
          setCollapsed(_collapsed);
        }
      }}
      theme="dark"
      width={300}
      collapsible
      collapsed={collapsed}
      className="main-nav-menu-slider"
    >
      {/* <div className="logo" style={{ width: collapsed ? 80 : undefined }}>
        {!collapsed && (
          <img className="logo-img" src={longLogo} alt="Theora Logo" />
        )}
        {collapsed && (
          <img className="square-logo-img" src={logo} alt="Theora Logo" />
        )}
      </div> */}
      <div style={{ height: 48 }} />
      {children}
      <style jsx>{`
        .logo {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .square-logo-img {
          height: 56px;
          padding: 16px;
        }
        .logo-img {
          height: 56px;
          padding-top: 18px;
        }
        .spacer {
          margin: auto;
        }
      `}</style>
    </Sider>
  );
}

function MainNavMenu() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();
  const currentItem = useMemo(() => {
    const path = location.pathname;
    if (path === '/home') {
      return 'home';
    }
    return path.slice(1);
  }, [location]);
  const onMenuClick = useCallback(
    ({ item, key, keyPath, domEvent }) => {
      if (key === 'sign-out') {
        authContext.signOut();
        history.push('/sign-in');
        notification.open({
          message: 'Success',
          description: `Successfully signed out!`,
        });
      }
    },
    [authContext, history],
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  const profile = useProfile();
  if (responsiveMode === 'mobile') {
    return (
      <>
        <Layout.Header
          className="main-nav-header"
          style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <img className="esp-logo-img" src={espLogo} alt="Theora Logo" />
          <Button
            key="1"
            type="primary"
            icon={<MenuOutlined onClick={() => setDrawerVisible(true)} />}
          />
        </Layout.Header>
        <div style={{ height: 80 }} />
        <Drawer
          title="Theora ESP™ Lite"
          placement="right"
          closable={true}
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          className="main-nav-drawer"
        >
          <MainMenu
            onMenuClick={onMenuClick}
            currentItem={currentItem}
            permissions={profile && profile.role && profile.role.permissions}
          />
        </Drawer>
        <style jsx global>{`
          .logo-img {
            height: 56px;
            margin-top: 12px;
            margin-bottom: 12px;
            margin-left: 16px;
          }
          .esp-logo-img {
            height: 40px;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: 16px;
          }
          .main-nav-menu {
            height: calc(100% - 80px);
          }
          .main-nav-header {
            background-color: black;
            height: unset;
            padding-left: 0;
            padding-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .main-nav-drawer .ant-drawer-body {
            padding: 0px;
          }
        `}</style>
      </>
    );
  }
  return (
    <MainSider collapsed={collapsed} setCollapsed={setCollapsed}>
      <MainMenu
        onMenuClick={onMenuClick}
        currentItem={currentItem}
        permissions={profile && profile.role && profile.role.permissions}
      />
    </MainSider>
  );
}

export default MainNavMenu;
