import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  EditOutlined,
  ExportOutlined,
  MoreOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  List,
  Skeleton,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import useProfile from '../../../shared/use-profile';
import { titleCase } from '../../../shared/utils';
import TenantAdminResetDeviceModal from '../TenantAdminResetDeviceModal';
import { AuditStatuses } from '../constants';
import CellUsage from './CellUsage';
import EditDeviceMetadataModal from './EditDeviceMetadataModal';
import EditNotesModal from './EditNotesModal';
import EditWearerDetailsModal from './EditWearerDetailsModal';
import JoonDeviceLogs from './JoonDeviceLogs';
import SimpleUsage from './SimpleUsage';
import JoonDeviceCommands from './commands/JoonDeviceCommands';
import TenantAdminCommands from './commands/TenantAdminCommands';
import GraphsTab from './event-reports/GraphsTab';
import Joon3ContactsList from './contacts/Joon3ContactsList';
import { getFormattedPhoneNumber } from './contacts/utils';
import DailyStats from './daily-stats/DailyStats';
import JoonDatasets from './datasets/JoonDeviceDatasets';
import JoonDeviceEvents from './events/JoonDeviceEvents';
import ViewDeviceMapTab from './map-tab/ViewDeviceMapTab';
import ViewDeviceLocationHistoryTab from './location-history/ViewDeviceLocationHistoryTab';
import SettingsV2Tab from './settings-v2/SettingsV2Tab';
import useCmdAcks from './use-command_acknowledgements';
import useFilterJoonDevices from './use-filter-joon-devices';
import useJoonDeviceUpdates from './use-joon-device-updates';
import useJoonNowUpdates from './use-joon-now-updates';
import JoonDeviceUsers from './users/JoonDeviceUsers';
import useUserXJoon3Updates from './users/use-user-x-joon3-updates';
import WifiNetworksTab from './wifi-tab/WifiNetworksTab';
import { CallCenterCos } from './settings-v2/constants';
import SafeZones from './safe-zones/SafeZones';
import useGeofenceUpdates from './safe-zones/use-geofence-updates';
import LatestFields from './LatestFields';
import DisplayField from './DisplayField';

const { Title, Text, Paragraph } = Typography;

function getConfStatus(joonDevice) {
  const status =
    joonDevice.auditStatus && AuditStatuses[joonDevice.auditStatus];
  if (status) {
    return <Tag color={status.color}>{status.label}</Tag>;
  }
  return null;
}

function ViewJoonDevice() {
  const { _id, _tab } = useParams();
  const history = useHistory();
  const location = useLocation();
  useUserXJoon3Updates(_id);

  useEffect(() => {
    if (!_tab && location.pathname.startsWith('/joon-device/view')) {
      history.push(`/joon-device/view/${_id}/daily-stats`);
    }
  }, [_tab, _id, history, location]);

  const [refreshes, setRefreshes] = useState(0);
  const refresh = useCallback(() => {
    setRefreshes(refreshes + 1);
  }, [refreshes]);
  const filters = useRef({ _id });
  useCmdAcks(_id);
  useJoonNowUpdates(_id);
  const joonNow = useSelector(
    (store) => store.joonNows[_id]?.[_id],
    shallowEqual,
  );
  useJoonDeviceUpdates(filters.current);
  const devices = useFilterJoonDevices({ filters: filters.current });
  const { error, loading, joonDevice } = useMemo(() => {
    const device = devices && devices[0];
    return {
      error: null,
      joonDevice: device,
      loading: !device,
    };
  }, [devices]);
  const queryId = 'view-joon-device';

  useGeofenceUpdates(joonDevice && joonDevice._id);

  const profile = useProfile();
  const showUpdate = useMemo(() => {
    return (
      profile &&
      profile.role &&
      profile.role.permissions &&
      profile.role.permissions.includes('update-joon3:all')
    );
  }, [profile]);
  const showTenant = useMemo(() => {
    return (
      profile.role &&
      profile.role.permissions &&
      profile.role.permissions.includes('list-tenants:all')
    );
  }, [profile]);
  const tenantAdminMode = useMemo(() => {
    return (
      !profile?.role?.permissions?.includes('update-joon3:all') &&
      profile?.role?.permissions?.includes(
        'update-meta-joon3:tenant>{{myTenantId}}',
      )
    );
  }, [profile]);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const joonDeviceFields = useMemo(() => {
    if (joonDevice) {
      return [
        {
          title: 'IMEI',
          value: joonDevice._id,
        },
        {
          title: 'UDI',
          value: joonDevice.mgUdi,
        },
        {
          title: 'Serial No.',
          value: joonDevice.serialNo,
        },
        {
          title: 'ICCID',
          value: joonDevice.iccid,
        },
        {
          title: 'IMSI',
          value: joonDevice.imsi,
        },
        {
          title: 'Phone Number',
          value: getFormattedPhoneNumber(joonDevice.msisdn),
        },
        {
          title: 'Call Center ID',
          value: joonDevice.callCenterId,
        },
        {
          title: 'Configuration',
          value: joonDevice.auditStatus,
          component: getConfStatus(joonDevice),
        },
        {
          title: 'SIM Status',
          value:
            joonDevice.simStatus &&
            titleCase(joonDevice.simStatus.toLowerCase()),
        },
        {
          title: 'Activation Date',
          value:
            joonDevice.activatedAt &&
            new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(joonDevice.activatedAt)),
        },
        showTenant && {
          title: 'Tenant',
          component: (joonDevice.tenant && (
            <Link
              to={`/tenant/view/${joonDevice.tenantId}/settings`}
              style={{ color: settings.colors.blue }}
            >
              {joonDevice.tenant.name}
            </Link>
          )) || <Text>Not Specified</Text>,
        },
        showTenant && {
          title: 'Tenant Call Center',
          value:
            (joonDevice.tenant &&
              joonDevice.tenant.callCenterCo &&
              CallCenterCos[joonDevice.tenant.callCenterCo] &&
              CallCenterCos[joonDevice.tenant.callCenterCo].label) ||
            'Not Specified',
        },
        {
          title: 'Fall Detection',
          value: <p>{joonNow.fallDetectionEnabled ? 'Enabled' : 'Disabled'}</p>,
        },
        {
          title: 'Environment',
          value: joonDevice.envName,
        },
        {
          title: 'Description',
          value: joonDevice.desc,
        },
        {
          title: 'Tags',
          value: joonDevice.tags,
          component:
            joonDevice.tags &&
            joonDevice.tags.map((tag) => (
              <Tag key={tag} color={settings.colors.secondary}>
                {tag}
              </Tag>
            )),
        },
      ].filter((a) => a && (a.value || a.component));
    }
  }, [joonDevice, showTenant, locale]);

  const wearerFields = useMemo(() => {
    if (joonDevice) {
      return [
        {
          title: 'Name',
          value: joonDevice.wearerName,
        },
        {
          title: 'Phone Number',
          value: joonDevice.wearerPhone,
        },
        {
          title: 'Email',
          value: joonDevice.wearerEmail,
        },
        {
          title: 'Address',
          value:
            joonDevice.wearerAddress &&
            (joonDevice.wearerAddress.address1 ||
              joonDevice.wearerAddress.address2 ||
              joonDevice.wearerAddress.city ||
              joonDevice.wearerAddress.state ||
              joonDevice.wearerAddress.country),
          component: joonDevice.wearerAddress && (
            <>
              {joonDevice.wearerAddress.address1 && (
                <>
                  <Text>{joonDevice.wearerAddress.address1}</Text>
                  <br />
                </>
              )}
              {joonDevice.wearerAddress.address2 && (
                <>
                  <Text>{joonDevice.wearerAddress.address2}</Text>
                  <br />
                </>
              )}
              {joonDevice.wearerAddress.city && (
                <Text>{`${joonDevice.wearerAddress.city}, `}</Text>
              )}
              {joonDevice.wearerAddress.state && (
                <Text>{`${joonDevice.wearerAddress.state} `}</Text>
              )}
              {joonDevice.wearerAddress.zipCode && (
                <Text>{joonDevice.wearerAddress.zipCode}</Text>
              )}
              {(joonDevice.wearerAddress.city ||
                joonDevice.wearerAddress.state ||
                joonDevice.wearerAddress.zipCode) && <br />}
              {joonDevice.wearerAddress.country && (
                <>
                  <Text>{joonDevice.wearerAddress.country}</Text>
                </>
              )}
            </>
          ),
        },
      ].filter((a) => a.value);
    }
  }, [joonDevice]);

  const handleExportToCsv = useRef();

  const [editing, setEditing] = useState();
  const handleCancelEditingWearer = useCallback(() => {
    setEditing(undefined);
  }, []);
  const handleFinishEditingWearer = useCallback(() => {
    setEditing(undefined);
  }, []);
  const handleEditWearer = useCallback(() => {
    setEditing({ joonDevice, queryId });
  }, [joonDevice]);

  const [editingNotes, setEditingNotes] = useState();
  const handleCancelEditingNotes = useCallback(() => {
    setEditingNotes(undefined);
  }, []);
  const handleFinishEditingNotes = useCallback(() => {
    setEditingNotes(undefined);
  }, []);
  const handleEditNotes = useCallback(() => {
    setEditingNotes({ joonDevice, queryId });
  }, [joonDevice]);

  const [editingMetadata, setEditingMetadata] = useState();
  const handleCancelEditingMetadata = useCallback(() => {
    setEditingMetadata(undefined);
  }, []);
  const handleFinishEditingMetadata = useCallback(() => {
    setEditingMetadata(undefined);
  }, []);
  const handleEditMetadata = useCallback(() => {
    setEditingMetadata({ joonDevice, queryId });
  }, [joonDevice]);

  const [resetting, setResetting] = useState();
  const handleCancelReset = useCallback(() => {
    setResetting(undefined);
  }, []);
  const handleFinishResetting = useCallback(() => {
    setResetting(undefined);
  }, []);
  const handleReset = useCallback(() => {
    setResetting({ joonDevice, queryId });
  }, [joonDevice]);

  return (
    <CSPage
      title="Theora Connect&trade; Device"
      containerStyle={{ maxWidth: 'unset' }}
    >
      <CSPageHeader
        titleComponent={
          <div style={{ position: 'relative' }}>
            <Title className="cs-header-title" style={{ marginBottom: 8 }}>
              <ClockCircleOutlined style={{ marginRight: 16 }} />
              Theora Connect&trade; Device
            </Title>
            <div style={{ position: 'absolute', right: 0, top: 0 }}>
              {showUpdate ? (
                <Link
                  to={`/joon-device/edit/${_id}?${new URLSearchParams({
                    from: location.pathname,
                  })}`}
                >
                  <Button size="large" type="text" icon={<EditOutlined />} />
                </Link>
              ) : (
                <Tooltip title="Edit Metadata">
                  <Button
                    style={{ marginLeft: 16, height: 'unset' }}
                    type="text"
                    icon={<EditOutlined />}
                    onClick={handleEditMetadata}
                  >
                    Edit Metadata
                  </Button>
                </Tooltip>
              )}
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'reset',
                      icon: <WarningOutlined />,
                      onClick: handleReset,
                      label: 'Reset This Device',
                    },
                  ],
                }}
                trigger="click"
              >
                <Button type="text" icon={<MoreOutlined />} />
              </Dropdown>
            </div>
          </div>
        }
        backActions={[
          <Link to="/joon-devices" key="back">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to Theora Connect Devices
            </Button>
          </Link>,
        ]}
        topActions={
          _tab === 'usage' && [
            <Button
              key="export"
              type="link"
              size="large"
              icon={<ExportOutlined />}
              onClick={() =>
                handleExportToCsv.current && handleExportToCsv.current()
              }
            >
              Export to CSV
            </Button>,
          ]
        }
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-box">
        {!joonDevice && loading && <Skeleton active paragraph title />}
        <List
          dataSource={joonDeviceFields}
          renderItem={(item) => (
            <DisplayField
              title={item.title}
              value={item.value}
              component={item.component}
            />
          )}
          loading={loading}
          locale={{
            emptyText: <div />,
          }}
          grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
        />
        {joonNow && _id && (
          <LatestFields id={_id} joonNow={joonNow} loading={loading} />
        )}
        {joonDevice && !loading && (
          <Title level={4}>
            Wearer Details
            <Button
              style={{ marginLeft: 16, height: 'unset' }}
              type="text"
              icon={<EditOutlined />}
              onClick={handleEditWearer}
            />
          </Title>
        )}
        {wearerFields && wearerFields.length > 0 ? (
          <List
            dataSource={wearerFields}
            renderItem={(item) => (
              <DisplayField
                title={item.title}
                value={item.value}
                component={item.component}
              />
            )}
            loading={loading}
            locale={{
              emptyText: <div />,
            }}
            grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
          />
        ) : (
          <div>No Wearer Details</div>
        )}
        <Title level={4}>
          Notes
          <Button
            style={{ marginLeft: 16, height: 'unset' }}
            type="text"
            icon={<EditOutlined />}
            onClick={handleEditNotes}
          />
        </Title>
        {joonDevice && joonDevice.notes && (
          <>
            {joonDevice.notes.split(/\r?\n/).map((line, index) => (
              <Paragraph key={index} style={{ marginBottom: 0 }}>
                {line}
              </Paragraph>
            ))}
          </>
        )}
      </div>
      <Tabs
        defaultActiveKey={showUpdate ? 'daily-stats' : 'settings'}
        activeKey={_tab}
        onChange={(key) => history.push(`/joon-device/view/${_id}/${key}`)}
        size="large"
      >
        {showUpdate && (
          <Tabs.TabPane tab="Daily Stats" key="daily-stats">
            {joonDevice && (
              <DailyStats
                joonDevice={joonDevice}
                handleExportToCsv={handleExportToCsv}
              />
            )}
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Map" key="map">
          <ViewDeviceMapTab
            joonDevice={joonDevice}
            joonNow={joonNow}
            hasSafeZonePermissions={showTenant}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Location History" key="location-history">
          {joonDevice && (
            <ViewDeviceLocationHistoryTab
              joonDevice={joonDevice}
              hasSafeZonePermissions={showTenant}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Event Log" key="event-log">
          {joonDevice && <JoonDeviceEvents joonDevice={joonDevice} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Event Graphs" key="event-graphs">
          {joonDevice && <GraphsTab joonDevice={joonDevice} />}
        </Tabs.TabPane>
        {showUpdate && (
          <Tabs.TabPane tab="Datasets" key="datasets">
            {joonDevice && <JoonDatasets joonDevice={joonDevice} />}
          </Tabs.TabPane>
        )}
        {showUpdate && (
          <Tabs.TabPane tab="Commands" key="commands">
            {joonDevice && <JoonDeviceCommands joonDevice={joonDevice} />}
          </Tabs.TabPane>
        )}
        {tenantAdminMode && (
          <Tabs.TabPane tab="Remote Control" key="commands">
            {joonDevice && <TenantAdminCommands joonDevice={joonDevice} />}
          </Tabs.TabPane>
        )}
        {/* {showUpdate && (
          <Tabs.TabPane tab="Settings" key="settings">
            {joonDevice && (
              <JoonDeviceFactoryAdminSettings
                joonDevice={joonDevice}
                refresh={refresh}
              />
            )}
          </Tabs.TabPane>
        )} */}
        {/* {tenantAdminMode && (
          <Tabs.TabPane tab="Settings" key="settings">
            {joonDevice && (
              <JoonDeviceTenantAdminSettings
                joonDevice={joonDevice}
                refresh={refresh}
              />
            )}
          </Tabs.TabPane>
        )} */}
        <Tabs.TabPane tab="Settings" key="settings">
          {joonDevice && <SettingsV2Tab joon3={joonDevice} refresh={refresh} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Wi-Fi / Safe-Zones" key="wifi">
          {joonDevice && (
            <>
              <WifiNetworksTab joon3={joonDevice} refresh={refresh} />
              <SafeZones joonDevice={joonDevice} />
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Users" key="users">
          {joonDevice && (
            <JoonDeviceUsers joonDevice={joonDevice} queryId={queryId} />
          )}
        </Tabs.TabPane>
        {showUpdate && (
          <Tabs.TabPane tab="Simple Usage" key="usage">
            {joonDevice && (
              <SimpleUsage
                joonDevice={joonDevice}
                handleExportToCsv={handleExportToCsv}
              />
            )}
          </Tabs.TabPane>
        )}
        {showUpdate && (
          <Tabs.TabPane tab="Cell Usage" key="cell-usage">
            {joonDevice && <CellUsage joonDevice={joonDevice} />}
          </Tabs.TabPane>
        )}
        {showUpdate && (
          <Tabs.TabPane tab="Contacts" key="contacts">
            {joonDevice && <Joon3ContactsList joonDevice={joonDevice} />}
          </Tabs.TabPane>
        )}
        {showUpdate && (
          <Tabs.TabPane tab="Device Logs" key="device-logs">
            {joonDevice && <JoonDeviceLogs joonDevice={joonDevice} />}
          </Tabs.TabPane>
        )}
      </Tabs>
      <EditWearerDetailsModal
        visible={editing}
        onFinish={handleFinishEditingWearer}
        onCancel={handleCancelEditingWearer}
      />
      <EditNotesModal
        visible={editingNotes}
        onFinish={handleFinishEditingNotes}
        onCancel={handleCancelEditingNotes}
      />
      <EditDeviceMetadataModal
        visible={editingMetadata}
        onFinish={handleFinishEditingMetadata}
        onCancel={handleCancelEditingMetadata}
      />
      <TenantAdminResetDeviceModal
        visible={resetting}
        onFinish={handleFinishResetting}
        onCancel={handleCancelReset}
      />
      <style jsx>{`
        .top-box {
          border: 1px solid ${settings.colors.borderGray};
          border-radius: 24px;
          padding: 16px 24px;
          margin-bottom: 24px;
        }
      `}</style>
    </CSPage>
  );
}

export default ViewJoonDevice;
