import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoonNowAction,
  deleteJoonNowAction,
  updateJoonNowAction,
} from '../../../redux-store/joon-nows-store';

const TenantJoonNowAttributes = gql`
  {
    _id
    screeOnSecs
    onChargerSecs
    onSecs
    stepCount
    stepsSinceMidnight
    fallDetectionEnabled
    detectedFallCount
    verifiedFallCount
    cancelledFallCount
    noResponseFallCount
    callMadeFallCount
    sosCalls
    fwVer
    appVer
    appFlavor
    confVer
    signalLevel
    batteryLevel
    loc {
      type
      lat
      lon
      alt
      acc
      speed
      bearing
    }
    drain
    timezone
    isPoweredOn
    isCharging
    isTurboMode
    wifiSsid
    latestMsgTimestampMs
    latestLocationTimestampMs
    latestHeartbeatTimestampMs
    updatedAt
  }
`;

const TenantJoonNowUpdateAttributes = gql`
{
  type
  crud
  id
  new ${TenantJoonNowAttributes}
}
`;

const allTenantJoon3NowUpdatesSubscription = gql`
  subscription AllTenantJoon3NowUpdates($tenantId: ID!) {
    allTenantJoon3NowUpdates(tenantId: $tenantId) ${TenantJoonNowUpdateAttributes}
  }
`;

function useTenantNowUpdates(tenantId) {
  const queryId = tenantId || 'default';
  const dispatch = useDispatch();
  useSubscription(allTenantJoon3NowUpdatesSubscription, {
    skip: !tenantId,
    variables: {
      tenantId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const updates = result?.data?.allTenantJoon3NowUpdates;
        updates
          .filter((u) => u)
          .forEach((update) => {
            switch (update.type) {
              case 'JOON_NOW_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoonNowAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoonNowAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoonNowAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoonNowAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useTenantNowUpdates;
