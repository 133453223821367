import { List, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import humanizeDuration from 'humanize-duration';

import { JoonLocationTypes } from '../constants';
import useUpdatedAgo from './use-updated-ago';
import DisplayField from './DisplayField';
import {
  WeakCellSignalSvg,
  FairCellSignalSvg,
  GoodCellSignalSvg,
  StrongCellSignalSvg,
  NoCellSignalSvg,
  ExtremelyWeakCellSignalSvg,
  FullBatterySvg,
  ThreeQuartersBatterySvg,
  HalfBatterySvg,
  OneQuarterBatterySvg,
  LowBatterySvg,
  NoBatterySvg,
} from '../../../icons/icons';

const { Title } = Typography;

function LatestFields({ id, joonNow, loading }) {
  const updatedAgo = useUpdatedAgo(id);

  const latestCellSignal = useMemo(() => {
    return joonNow && typeof joonNow.signalLevel === 'number';
  }, [joonNow]);

  const cellSignalSvg = useMemo(() => {
    if (latestCellSignal) {
      const level = joonNow.signalLevel;
      if (level > -80) return <StrongCellSignalSvg />;
      else if (level > -90) return <GoodCellSignalSvg />;
      else if (level > -100) return <FairCellSignalSvg />;
      else if (level > -110) return <WeakCellSignalSvg />;
      else if (level > -130) return <ExtremelyWeakCellSignalSvg />;
      else return <NoCellSignalSvg />;
    }
    return <NoCellSignalSvg />;
  }, [joonNow, latestCellSignal]);

  const latestBatteryLevel = useMemo(() => {
    return joonNow && typeof joonNow.batteryLevel === 'number';
  }, [joonNow]);

  const batteryLevelSvg = useMemo(() => {
    if (latestBatteryLevel) {
      const level = joonNow.batteryLevel;
      if (level > 95) return <FullBatterySvg />;
      else if (level > 70) return <ThreeQuartersBatterySvg />;
      else if (level > 45) return <HalfBatterySvg />;
      else if (level > 20) return <OneQuarterBatterySvg />;
      else if (level > 0) return <LowBatterySvg />;
      else return <NoBatterySvg />;
    }
    return <NoBatterySvg />;
  }, [joonNow, latestBatteryLevel]);

  const latestFields = useMemo(() => {
    if (joonNow) {
      return [
        joonNow?.loc && {
          title: 'Latest Location',
          component: (
            <div>
              <div>
                {JoonLocationTypes[joonNow.loc?.type] &&
                  JoonLocationTypes[joonNow.loc?.type].label}
              </div>
              {joonNow.loc.acc && (
                <div>{`Device within ${new Intl.NumberFormat('en-US', {
                  style: 'unit',
                  unit: 'meter',
                  maximumFractionDigits: 0,
                  unitDisplay: 'long',
                }).format(joonNow.loc.acc)} of`}</div>
              )}
              <div>{`${joonNow.loc.lat}, ${joonNow.loc.lon}`}</div>
              <div>
                <Link to={`/joon-device/view/${joonNow._id}/map`}>
                  View on Map
                </Link>
              </div>
            </div>
          ),
        },
        latestCellSignal && {
          title: 'Last Cell Signal',
          value: (
            <div className="stat-icon-container">
              <span>{`${joonNow.signalLevel} dBm`}</span> {cellSignalSvg}
            </div>
          ),
        },
        latestBatteryLevel && {
          title: 'Last Battery Level',
          value: (
            <div className="stat-icon-container">
              <span>{`${joonNow.batteryLevel}%`}</span> {batteryLevelSvg}
            </div>
          ),
        },
        updatedAgo && {
          title: 'Latest Location Update',
          value: updatedAgo.location,
        },
        updatedAgo && {
          title: 'Latest Ping',
          value: updatedAgo.comms,
        },
        joonNow.sinceBootMs && {
          title: 'Since Boot',
          value: `${humanizeDuration(
            new Date().getTime() -
              joonNow.latestLocationTimestampMs +
              joonNow.sinceBootMs,
            {
              largest: 1,
              round: true,
            },
          )} ago`,
        },
      ].filter((a) => a && (a.value || a.component));
    }
  }, [joonNow, updatedAgo]);

  if (!latestFields || !latestFields.length) return <p>No latest fields.</p>;
  return (
    <>
      <Title level={4}>Latest</Title>
      <List
        dataSource={latestFields}
        renderItem={(item) => (
          <DisplayField
            title={item.title}
            value={item.value}
            component={item.component}
          />
        )}
        loading={loading}
        locale={{
          emptyText: <div />,
        }}
        grid={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 4,
          xl: 4,
          xxl: 5,
          gutter: 16,
        }}
      />
      <style jsx>{`
        .stat-icon-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          padding-top: 4px;
          svg {
            max-height: 16px;
          }
        }
      `}</style>
    </>
  );
}

export default LatestFields;
