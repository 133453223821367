import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, List, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PhotoCircle from '../../../../components/PhotoCircle';
import settings from '../../../../settings';
import { generateUuid } from '../../../../shared/utils';
import AddJoon3ContactModal from './AddJoon3ContactModal';
import DeleteJoon3ContactModal from './DeleteJoon3ContactModal';
import useFilterJoon3Contacts from './use-filter-joon3-contacts';
import useJoon3ContactUpdates from './use-joon3-contact-updates';
import { getFormattedPhoneNumber } from './utils';
import useProfile from '../../../../shared/use-profile';

const { Title } = Typography;

function Joon3ContactItem({
  item,
  onDeleteClick,
  onEditClick,
  locale,
  showUpdate,
}) {
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  });
  return (
    <List.Item>
      <Card bodyStyle={{ textAlign: 'center', position: 'relative' }}>
        {showUpdate && (
          <div style={{ top: 0, right: 0, position: 'absolute' }}>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => onEditClick(item)}
            />
            {!item.userId && (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => onDeleteClick(item)}
              />
            )}
          </div>
        )}
        {item && (
          <div
            style={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PhotoCircle
              color={settings.colors.secondary}
              diameter={100}
              imageUrl={item.primaryThumbnailUrl}
              textStyle={{ color: 'white', fontSize: 18 }}
              initials={
                item.name &&
                item.name
                  .split(' ')
                  .map((n) => n && n[0])
                  .filter((l) => l)
                  .join('')
              }
            />
          </div>
        )}
        {item && (
          <Title level={5} style={{ marginBottom: 0 }}>
            {item.name}
          </Title>
        )}
        {item && (
          <div style={{ marginBottom: 8 }}>
            <div>{getFormattedPhoneNumber(item.phone)}</div>
            <div>{item.isDisplayed ? 'Displayed' : 'Hidden'}</div>
            <div>{dateFormat.format(new Date(item.updatedAt))}</div>
          </div>
        )}
      </Card>
    </List.Item>
  );
}

function Joon3ContactsList({ joonDevice }) {
  useJoon3ContactUpdates(joonDevice._id);
  const profile = useProfile();
  const showUpdate = useMemo(() => {
    return profile?.role?.permissions?.includes('update-joon3:all');
  }, [profile]);
  const contacts = useFilterJoon3Contacts({
    deviceId: joonDevice._id,
    filters: {},
  });
  const [deleting, setDeleting] = useState();
  const onCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const onDeleteSuccess = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const onDeleteClick = useCallback((contact) => {
    setDeleting({ contact });
  }, []);

  const [adding, setAdding] = useState();
  const onCancelAdd = useCallback(() => {
    setAdding(undefined);
  }, []);
  const onAddSuccess = useCallback(() => {
    setAdding(undefined);
  }, []);
  const onAddClick = useCallback(() => {
    setAdding({ joonDevice, creating: { _id: generateUuid() } });
  }, [joonDevice]);
  const onEditClick = useCallback(
    (contact) => {
      setAdding({ joonDevice, existing: contact });
    },
    [joonDevice],
  );
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      <div className="top-actions"></div>
      <List
        dataSource={contacts}
        renderItem={(item) => (
          <Joon3ContactItem
            item={item}
            onDeleteClick={onDeleteClick}
            onEditClick={() => onEditClick(item)}
            locale={locale}
            showUpdate={showUpdate}
          />
        )}
        locale={{
          emptyText: <div />,
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
      />
      {showUpdate && (
        <div style={{ textAlign: 'center' }}>
          <Button icon={<PlusOutlined />} onClick={() => onAddClick()}>
            Add a Device Contact
          </Button>
        </div>
      )}
      <DeleteJoon3ContactModal
        visible={deleting}
        onCancel={onCancelDelete}
        onSuccess={onDeleteSuccess}
      />
      <AddJoon3ContactModal
        visible={adding}
        onCancel={onCancelAdd}
        onSuccess={onAddSuccess}
      />
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
}

export default Joon3ContactsList;
