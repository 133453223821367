import {
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { readTenantsAction } from '../../../redux-store/tenants-store';
import settings from '../../../settings';
import useMany from '../../../shared/use-many';
import { generateUuid } from '../../../shared/utils';
import { allTenantsQuery } from '../constants';
import { BootAnimationScreens } from '../../joonDevices/constants';
import { CallCenterCos } from '../../joonDevices/ViewJoonDevice/settings-v2/constants';

const { Title, Text } = Typography;

function ListTenants() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: tenants,
    refetch,
  } = useMany(
    allTenantsQuery,
    (data) => data.allTenants,
    readTenantsAction,
    'tenants',
    ['name', 'desc', 'permissions'],
    settings.querySize,
    {},
    (a, b) => a.name.localeCompare(b.name, locale),
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <CSPage title="Tenants" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <ShopOutlined style={{ marginRight: 16 }} />
            Tenants
          </Title>
        }
        topActions={[
          <Link
            key="add"
            to={{
              pathname: `tenant/edit/${generateUuid()}`,
              state: { mode: 'create' },
            }}
          >
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new tenant
            </Button>
          </Link>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Table
        dataSource={tenants}
        loading={!tenants.length && loading}
        pagination={{ ...pagination, total: tenants.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="Name" dataIndex="name" key="name" />
        <Column
          title="Call Center"
          dataIndex="callCenterCo"
          render={(text, record) => {
            return CallCenterCos[record.callCenterCo]?.label;
          }}
        />
        <Column
          title="Boot Animation"
          dataIndex="bootAnimation"
          render={(text, record) => {
            return (
              BootAnimationScreens[record.bootAnimation] &&
              BootAnimationScreens[record.bootAnimation].label
            );
          }}
        />
        <Column
          title="Created"
          dataIndex="createdAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.createdAt));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  key="add"
                  to={{
                    pathname: `/tenant/view/${record._id}/settings`,
                  }}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              <Tooltip title="Edit">
                <Link
                  key="add"
                  to={{
                    pathname: `/tenant/edit/${record._id}`,
                  }}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
      `}</style>
    </CSPage>
  );
}

export default ListTenants;
